<template>
  <div class="about" style='padding-left: 5pt;font-size: 14pt;'>
    	<div style='height: 24pt;'>
		<div style='float:left;'>Please, find and rescue me: <b>{{findMe}}</b></div>
		<div style='float:right;'>Points: <b>{{points}} / {{winning}}</b></div>
		</div>
		
		<div style='height: 24pt; width: 100%;'>
		<Button style='float:left;' :disabled="started" @click=start>START</button>	
		<Button v-if="sign=='+'" style='float:right;' @click="switchTo('x')">PLUS</button>	
		<Button v-else style='float:right;' @click="switchTo('+')">MULTIPLY</button>	
		<Button style='float:right;' @click="clear(0)">RESET</button>
		<Button style='float:right;' @click="inc">one more</button>
		<Button style='float:right;' @click="dec">one less</button>
		</div>
		<br/>
		<div style='width: 100%;'>
		<div v-if="points==winning" >	
			<div v-if="Math.random()>.5">
			<iframe src="https://giphy.com/embed/7SfAXqgRgh0li" width="480" height="346" style="" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/cat-animal-dance-7SfAXqgRgh0li">via GIPHY</a></p>	
			</div>
			<div v-else>
			<iframe src="https://giphy.com/embed/Rznz8HjrKQAOQ" width="361" height="480" style="" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/cat-cute-pet-Rznz8HjrKQAOQ">via GIPHY</a></p>
			</div>
		</div>
		<table v-else style='width: 90%;border: 1px solid black; border-collapse=separate'>
		<tr >
			<td style='text-align: right; border-right: 1px solid black; border-bottom: 1px solid #ccc;'></td>
			<td v-for="(x) in max" :key="x" style='text-align: center; border-right: 1px solid black; border-bottom: 1px solid #ccc;; color: blue; vertical-align: middle;'><b>{{x}}</b></td>
		</tr>
		<tr v-for="(y) in max" :key="y" style='height: 30pt; '>
			<td style='text-align: right;vertical-align: middle; color: blue; border-right: 1px solid black; border-bottom: 1px solid #ccc;'><b>{{y}}</b></td>
			<td v-for="(i) in max" :key="i" style='height: 30pt; text-align: right; border-right: 1px solid black; border-bottom: 1px solid #ccc;' 
				>
				<div @mouseenter="hoverLine( fn(i,y) )"
					 @mouseleave="leaveLine( fn(i,y) )"
					 @click="mark(i,y)"
					 :name="fn(i,y)" style="width: 100%; height: 100%; padding-top: 5pt;">
					<span style='color: black; padding-right: 5pt;font-size: 12pt;'>{{i+sign+y+"="}}</span>
					<span v-if="show(i,y)" class="solution"><b>{{fn(i,y)}}</b></span>
					<span v-else class="solution">?</span>
				</div>
			</td>
		</tr>	

		</table>
		</div>
		<br/>
		<div :style="'position:absolute; left: ' +animalX+'pt'">
			<mdicon size ="34" name="dog-side"/>
		</div>	
		<div :style="'position:absolute; left: 800pt; color: '+catCol">
					<mdicon :spin="catSpin" size ="34" name="cat"/>
		</div>
		
  </div>
</template>
<script>
var timers = [];
import { myTimer, receiveToken, HTTP, showError, myName, serviceAPI} from '../variables.js';
import { getCopyright } from '../utils.js';
import { getAppStyle, getAppStyleFG, setDarkMode, initAppMode, hexToRgb, isDark, getBG, getBGColor } from '@/AppStyle.js';
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'about',
  data() {
      return {
  		getCopyright,
  		igniteVersion: "",
  		wildflyVersion: "",
  		jdkVersion: "",
		marker: [],
		max: 10,
		findMe: 0,
		points: 0,
		animalX: 0,
		catCol: "black",
		catSpin: false,
		started: false,
		autoStart: true,
		winning: 10,
		sign: "+"
      }
  },
  methods:{
	hoverLine(line)
	{
	$("div[name="+line+"]").parent().addClass('reqLineBG');
	},
	leaveLine(line)
	{
	$("div[name="+line+"]").parent().removeClass('reqLineBG');
	},
	show( x, y)
	{
		return this.marker.includes( this.fn(x,y));
	},
	inc()
	{
		this.max++;
	},
	dec()
	{
		if ( this.max > 2)
			this.max--;
	},
	switchTo( newSign)
	{
		this.sign = newSign;
	},
	fn(a,b)
	{
		if ( this.sign == '+')
		{
			return a+b;
		}
		if ( this.sign == 'x')
		{
				return a*b;
		}
		return a*b;
	},
	clear( found)
	{
		
		clearInterval( timers.pop());
		clearInterval( timers.pop());
		this.animalX=0;
		this.marker=[];
		this.findMe = this.fn( 1+Math.floor(Math.random() * this.max), 1+Math.floor(Math.random() * this.max))
		if ( !found)
		{
			this.points = 0;
			if ( found === 0)
			{
				this.catSpin = false;
				this.catCol='black';
			}
			else
			{
				this.catSpin = true;
				this.catCol='red';
			}
			
		}
		this.started = false;
		if ( found === 0)
		{
			return;			
		}
		if ( this.autoStart && this.points < this.winning)
		{
			this.start();
		}
	},
	run()
	{
		this.animalX++;
	},
	start()
	{
		if ( this.points >= this.winning)
		{
			this.points = 0;
		}
		this.started = true;
		this.catCol='black';
		this.catSpin = false;
		timers.push( setInterval( this.run, 10));
		timers.push( setTimeout( this.clear, 8000));
	},
	mark( x, y)
	{
		this.marker=[];
		//timers.push( setInterval( this.run, 5));
		this.marker.push( this.fn(x,y));
		//timers.push( setTimeout( this.clear, 4000));
		if ( this.fn(x,y) == this.findMe)
		{
			this.points++;
			this.clear( true);
		}
	}
  },
  created()
  {
	this.findMe = this.fn( 1+Math.floor(Math.random() * this.max), 1+Math.floor(Math.random() * this.max))
  }
 }
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
  .reqLineBG { 
    background-color: #afa;
    color: #844 !important; 
  }
  .solution {
	diSplay: inline-flex;
	min-width: 24pt !important;
	
  }
td {
	cursor: pointer;
}  
</style>