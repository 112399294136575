<template>
<div class="home" style='position: relative; float: top; height: calc(100vH - 80px)'>
  <section class="hero is-dark"  :style="{ backgroundImage: `url('${image}')` }">
    <div class="hero-body" style='position: relative; float: top;'>
      <div class="container">
        <h1 class="title">
          Welcome to <nobr>{{ myName }}</nobr>     
        </h1>
        <h2 class="subtitle">
          next generation of ad-systems 
          
        </h2>
        <center>
        
        </center>
        <!-- 
        <center>
        <div class="button-block">
          <button class="button is-xl is-dark" @click="register">Sign Up and Register now</button>
        </div>
        </center>
        -->
      </div>
    </div>
  </section>
  
  <EventsList />
  
  <popup :popupData="popupData" ></popup>
  
  <div style='position: absolute; bottom: 0; text-align: right; width: 100%; padding-right: 20pt; font-size: 10pt; font-weight:bold;'>
   {{getCopyright()}}
  </div>
  
</div>
</template>
<script>
import axios from 'axios';
import EventsList from '../components/EventsList';
import {myName, getDomainPrefix} from '../variables';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import { getCopyright } from '@/utils.js';
import { reloadMenubar } from '@/breadCrumb.js';
import Popup from'@/components/Popup.vue';
export const image = '/bg/background'+Math.round(1+11*Math.random())+'.JPG';

export default {
  name: 'home',
  components: {
    EventsList,Popup
  },
   data () {
    return {
     myName, image,
     getCopyright,
     info: {},
     popupData : {
          "header" : "new genaration of advertising systems",
          "body" : "NEXT",
		  "more": "nextadsystem.com",
          "display" : "none"
        },
    }
  },
  methods: {
	  register() 
      {
            this.popupData.header = "Coming soon...";
            this.popupData.body = "...";
            this.popupData.display = "block";
        }
  },

  created() {
	  
      sessionStorage.ident=this.$route.params.ident;
	  if ( !sessionStorage.ident )
	  {
			sessionStorage.ident = getDomainPrefix();
	  }
      reloadMenubar();
      setDarkMode( false);
      initAppMode();
  }
}
</script>
<style lang="scss" scoped>
  .hero {    
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
  }
  .hero-body .title {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 40px 0 20px 0;
    font-size: 60px;
  }
  .info-body {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 20px 20px 20px 20px;
    background-color: coral;
    font-size: 60px;
  }
  .subtitle {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    font-size: 30px;
  }
  .button-block {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    //position: absolute;
    bottom: -150px;
    .button {
     // margin-right: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }
    .welcome {
      width: 400px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .is-xl {
    font-size: 1.7rem;
  }
</style>