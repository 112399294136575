import axios from 'axios';
import JQuery from "jquery";
import store from "@/store";
import router from './router'
import { setDarkMode } from '@/AppStyle.js';
import { md5 } from '@/auth';
import { locale } from 'core-js';
export var myName; 
export var myEnv = null;
export var DOMAIN = "api";

export var protocolConst = 'https://';
export var wsConst = 'wss://';
export var protocol = protocolConst;
export var serverConst = sessionStorage.server || "api-prod.nextadsystem.com"; 
export var portConst= sessionStorage.port || 8443; //90; //89
export var portWS= sessionStorage.port || 8443;//90; //89

let $ = JQuery;
export var port=portConst;//90; //89;
//export const port_LB = 93; //89;
export var port_LB = 89;
export var port_COMM = 93;
export var fixedPort = true;
export var serverInit = 0;
const defaultAPI = '/api/v1';
if ( fixedPort)
{
	port_COMM = portConst;
	port_LB = portConst;
}
 
export var server = serverConst; //"192.168.178.32"; // 192.168.178.32"; // 192.168.100.49
//export var server = "192.168.178.32"; // localhost"; //"192.168.178.32"; // 192.168.178.32"; // 192.168.100.49
export var APIURL = protocol+server+':'+port+defaultAPI;
//export var APIURLA = protocol+server+':'+portA+defaultAPI;
//export var APIURLB = protocol+server+':'+portB+defaultAPI;

export var HOME = protocol+server+':80/';
/*
export var WSURL = wsConst+server+':'+portWS+'/ws/v1';
export var WSNEWSURL = wsConst+server+':'+portWS+'/wsNews/v1';
export var WSNEWSURL_LB = wsConst+server+':'+portWS+'/wsNews/v1';
//export var WSNEWSURLA = wsConst+server+':'+portA+'/wsNews/v1';
//export var WSNEWSURLB = wsConst+server+':'+portB+'/wsNews/v1';
export var WSURLX = wsConst+server+':'+portWS+'/wsx/v1';
export var WSLURL = wsConst+server+':'+portWS+'/wsInit/v1';
*/
export var rotationAPI = '/protected/ROTATION';
export var DLoginServ = '/public/DService';
export var PlayListAPI = '/protected/PLAYLIST';
export var serviceAPI = '/protected/SERVICE';
export var inventoryInlineAPI = '/protected/InventoryInline';
export var copyAPI = '/protected/COPY';
export var searchAPI = '/protected/SEARCH';
export var dashboardInfoAPI = '/protected/DASHBOARDINFO';
export var invoiceAPI = '/protected/BILLING';
export var sudokuAPI = '/protected/SUDOKU';
export var invoiceView = '/invoiceMonthly';
export var userAPI = '/user';
export var tickerAPI = '/ticker';
export var fwAPI = '/protected/fw';
export var notifyAPI = '/protected/notify';
export var invAPI = '/protected/inv';
export var gridAPI = '/protected/gridUtil';
export var bookingImportAPI = '/protected/bookingUtil';
export var bngAPI = '/protected/BNG';
export var opsAPI = '/protected/OPERATIONS';
export var placementServiceAPI = '/protected/PlacementService';
export var asrAPI = '/protected/ASRUN';
export var reportAPI = '/protected/REPORTS';
export var clientReportAPI = '/protected/CLIENT_REPORTS';
export var dealReportAPI = '/DEALREPORT';
export var rightsAPI = '/protected/RAP';
export var invExp = '/protected/BILLING_EXPORT'; 
export var infoAPI = '/card';
export var accessPointAPI = '/accessPoint';
export var tenantAPI = '/tenant';
export var authAPI = '/auth/oid';
export var myLogin = {};
export var tokenTimerStarted = false;
export var myTimer = [];
export var displayTimer = [];


var tenantInvAPI = null;
var tenantInvExpAPI = null;


export function findServer( callback )
{
	if ( sessionStorage.serverInit  )
	{
		return;
	}
	let lPort = portConst;
	findServerOnPort( lPort, callback);
	
}

export function getWSURL( )
{
	if ( sessionStorage.WSURLMAIN )
	{
		let WSURL = sessionStorage.WSURLMAIN+'/wsInit/v1';
		return WSURL;
	}
	let WSURL = wsConst+serverConst+':'+portWS+'/wsInit/v1'
	return WSURL;
}


export function findServerOnPort( lPort, callback)
{
	if ( fixedPort)
	{
		initServer(portConst, []); 
		if ( callback )
		{
			callback();
		}
	}
	else if ( lPort < 94 && !sessionStorage.serverInit)
	{
		console.log( protocol+server+':'+lPort+defaultAPI)
		HTTPTEST.defaults.baseURL = protocol+server+':'+lPort+defaultAPI;
		HTTPTEST.defaults.timeout=200;
		
		//alert(protocol+server+':'+lPort+defaultAPI)
		HTTPTEST.post( accessPointAPI+"/public/getServerStructure")
					.then( response => {
						//alert("OK")
								initServer(lPort, response.data); 
								if ( callback )
								{
									callback();
								}
					     })
						.catch( e => {
								findServerOnPort( lPort+1)
						      });
   }
}



export function getDomainPrefix()
{
	let hostname = window.location.host;
	if ( hostname && hostname.indexOf(".") > 0)
	{
		  let prefix = hostname.substring( 0, hostname.indexOf("."));
		  if (isNaN(prefix) && prefix != 'www') {  
		  	return prefix;
		  }
		  else
		  {
			  return "start";
		  }
	  }
	  else
	  {
      	return "start";
      }
}

export function initEnvironment()
{
	let initProcedure = new Promise(function (resolve, reject) {
		if ( !myEnv )
		{
			axios.get(
			    '/environment/env.json',
			    { baseURL: window.location.origin }
			  ).then( p=> {
				initENV( p.data);
				//console.log(myEnv.APP + " just loaded.")
				resolve( p.data);
			});
		}
		else
		{
			//console.log(myEnv.APP + " loaded.")
			resolve( myEnv);
		}
	});
	return initProcedure;
}

export function initENV( env)
{
	myEnv = env;
	myName = env.APP; 
	DOMAIN = env.DOMAIN;
	protocolConst = env.PROTOCOL;
	wsConst = env.WS;
	protocol = protocolConst;
	serverConst = env.APISERVER; // localhost"; //api.nextadsystem.com"
	portConst=env.PORT;//90; //89
	portWS=env.WSPORT;//90; //89
	sessionStorage.server = env.APISERVER;
	sessionStorage.port = env.PORT;
	sessionStorage.protocol = env.PROTOCOL;
	sessionStorage.WSURLMAIN = wsConst+serverConst+':'+portWS;
	//console.log( sessionStorage.server+":"+sessionStorage.port);
	let hostname = window.location.host;
	if ( hostname && hostname.indexOf(":") > 0)
	{
		hostname = hostname.substring( 0, hostname.indexOf(":"));
	}
	/*
	if ( hostname === "localhost" || hostname === "127.0.0.1")
	{
		  protocolConst = 'http://';
          wsConst = 'ws://';
		  protocol = protocolConst;
		  serverConst = hostname; // localhost"; //api.nextadsystem.com"
		  portConst=93;//90; //89
		  portWS=93;//90; //89
		  sessionStorage.WSURLMAIN = wsConst+server+':'+port;
		  //
		  sessionStorage.server = serverConst;
		  sessionStorage.port = portConst;
		  sessionStorage.protocol = protocol;
	}
	*/
	rebuildAPI( true)
}



function initServer( portFound, serverList )
{
	if ( fixedPort)
	{
		port = portConst;
	}
	else
	{
		port = 0;
		for ( let x in serverList )
		{
			let sv = serverList[x];
			if ( sv.communicate && sv.active )
			{
				port_COMM = sv.port;
			}
			else if ( sv.active && sv.lb)
			{
				port_LB = sv.port;
			}
			else if ( sv.active && !port)
			{
				port = sv.port;
			}
		}
		if ( !port && port_COMM)
		{
			port = port_COMM;
		}
	}
	sessionStorage.serverInit = new Date().getTime();
	//alert(portFound+"-> "+port+"/"+port_COMM+"/"+port_LB);
	rebuildAPI( true);
	
}

export function rebuildAPI( force)
{
	let oldURI = sessionStorage.server+":"+sessionStorage.port;
	let newURI = server+":"+port;
	
	if ( !sessionStorage.server || force)
	{
		sessionStorage.server = server;
		if ( fixedPort)
		{
			sessionStorage.port = portConst;
		}
		else
		{
    		sessionStorage.port = port;
    	}
    }
	APIURL = sessionStorage.APIURL || (protocol + server+':'+sessionStorage.port+defaultAPI);
	//WSURL = sessionStorage.WSURLMAIN?(sessionStorage.WSURLMAIN+'/ws/v1') : (wsConst+server+':'+port+'/ws/v1');
    if ( oldURI == newURI && !force)
    {
		return;
	}
	//sessionStorage.server = server;
    server = sessionStorage.server;
    port = sessionStorage.port;
    server = serverConst;
    sessionStorage.server = server;
	protocol = sessionStorage.protocol || protocol;
    
	//alert( sessionStorage.protocol+ "--> "+APIURL) 
    //APIURLA = protocol+server+':'+portA+defaultAPI;
    //APIURLB = protocol+server+':'+portB+defaultAPI;

    HOME = protocol+server+':8080/';
    //WSURL = wsConst+server+':'+port+'/ws/v1';
    
    //WSNEWSURLA = wsConst+server+':'+portA+'/wsNews/v1';
    //WSNEWSURLB = wsConst+server+':'+portB+'/wsNews/v1';
    //WSURLX = wsConst+server+':'+port+'/wsx/v1';
    //WSLURL = wsConst+server+':'+port+'/wsInit/v1';
    PlayListAPI = '/protected/PLAYLIST';
	if ( sessionStorage.playlistAPI )
	{
		PlayListAPI = sessionStorage.playlistAPI;
	}
    serviceAPI = '/protected/SERVICE';
    copyAPI = '/protected/COPY';
    searchAPI = '/protected/SEARCH';
    dashboardInfoAPI = '/protected/DASHBOARDINFO';
    invoiceAPI = '/protected/BILLING';
    if ( sessionStorage.tenantInvAPI )
    {
		invoiceAPI = sessionStorage.tenantInvAPI;
	}
	

										
    invoiceView = '/invoiceMonthly';
    userAPI = '/user';
	DLoginServ = '/public/DService';
    tickerAPI = '/ticker';
    fwAPI = '/protected/fw';
    notifyAPI = '/protected/notify';
    invAPI = '/protected/inv';
    gridAPI = '/protected/gridUtil';
	bookingImportAPI = '/protected/bookingUtil';
	placementServiceAPI = '/protected/PlacementService';
    bngAPI = '/protected/BNG';
    opsAPI = '/protected/OPERATIONS';
    asrAPI = '/protected/ASRUN';
    reportAPI = '/protected/REPORTS';
    clientReportAPI = '/protected/CLIENT_REPORTS';
    rightsAPI = '/protected/RAP';
    invExp = '/protected/BILLING_EXPORT'; 
    if ( sessionStorage.tenantInvExpAPI )
    {
		invExp = sessionStorage.tenantInvExpAPI;
	}
	//console.log( invExp);
    infoAPI = '/card';
    accessPointAPI = '/accessPoint';
    tenantAPI = '/tenant';
    authAPI = '/auth/oid';
	//axios.defaults.headers.common['Cache-Control'] = 'no-cache, must-revalidate';
    if ( HTTP )
    {
    	HTTP.defaults.baseURL = APIURL;
	}
	if ( HTTPMP )
    {
    	HTTPMP.defaults.baseURL = APIURL;
	}
	if ( HTTPAPI )
    {
    	HTTPAPI.defaults.baseURL = APIURL;
	}
	if ( HTTPTEST )
    {
    	HTTPTEST.defaults.baseURL = APIURL;
	}
	if ( HTTPX )
    {
    	HTTPX.defaults.baseURL = APIURL;
	}
	//if ( port == 89 )
	{
		//let WSNEWSURL_NEW = wsConst+server+':'+portWS+'/wsNews/v1';
		let WSNEWSURL_NEW = sessionStorage.WSURLMAIN?(sessionStorage.WSURLMAIN+'/wsNews/v1') : (wsConst+server+':'+port+'/wsNews/v1');
		if ( tickerDisplay )
		{
			reStartTicker( tickerDisplay, WSNEWSURL_NEW );
			let x = setInterval(function() {
				tickerDisplay( "switched server to " + server+":"+port+"/"+port_LB, "msgImportant");
				clearInterval(x);
			}, 50)
			
		}
	}
}		

rebuildAPI();

export function getAPIURL()
{
  	if ( sessionStorage.APIURL )
  	{
  		return sessionStorage.APIURL;		
  	}
  	if ( sessionStorage.port && sessionStorage.protocol && sessionStorage.server )
  	{
  		return sessionStorage.protocol+sessionStorage.server+":"+sessionStorage.port+defaultAPI;		
  	}
  	return protocol+sessionStorage.server+':'+sessionStorage.port+'/api/v1';
}

export var HTTP = axios.create({
  baseURL: APIURL,
  timeout: 12000
  ,
  headers: {
    Authorization: 'Bearer '+sessionStorage.token
  }
})

export var HTTPTEST = axios.create({
  baseURL: APIURL
  ,
  headers: {
    Authorization: 'Bearer '+sessionStorage.token
  }
})
export var HTTPAPI = axios.create({
  baseURL: APIURL
  ,
  headers: {
    Authorization: 'Bearer '+sessionStorage.token
  }
})


export var HTTPMP = axios.create({
  baseURL: APIURL,
  timeout: 50000000
  ,
  headers: {
    "content-type": "multipart/form-data",
    "accept-encoding": "multipart/form-data",
    "Authorization": 'Bearer '+sessionStorage.token
  }
})


export var HTTPX = axios.create({
  baseURL: APIURL
})

HTTP.interceptors.request.use(function (config) {
    // Do something before request is sent
    let authKey = sessionStorage.userToken;
    httpRequests++;
    lastRequest = new Date().getTime();
    config.headers["Authorization"] = "Bearer " + authKey;
    //config.headers["X-Custom-Header"] = sessionStorage.userToken;
    return config;
});

HTTPTEST.interceptors.request.use(function (config) {
    // Do something before request is sent
    let authKey = sessionStorage.userToken;
    httpRequests++;
    lastRequest = new Date().getTime();
    config.headers["Authorization"] = "Bearer " + authKey;
    //config.headers["X-Custom-Header"] = sessionStorage.userToken;
    return config;
});
HTTPAPI.interceptors.request.use(function (config) {
    // Do something before request is sent
    let authKey = sessionStorage.userToken;
    httpRequests++;
    lastRequest = new Date().getTime();
    config.headers["Authorization"] = "Bearer " + authKey;
    //config.headers["X-Custom-Header"] = sessionStorage.userToken;
    return config;
});

HTTPMP.interceptors.request.use(function (config) {
    // Do something before request is sent
    let authKey = sessionStorage.userToken;
    httpRequests++;
    lastRequest = new Date().getTime();
    config.headers["Authorization"] = "Bearer " + authKey;
    config.headers["accept-encoding"] = "multipart/form-data";
    config.headers["content-type"] = "multipart/form-data";
    //config.headers["X-Custom-Header"] = sessionStorage.userToken;
    return config;
});
var lastToast = 0;
HTTP.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    if ( sessionStorage.isExpired )
    {
		//
    }
    else
    {
        if ( error.message == "Network Error")
        {

			if ( toast )
			{
				if ( (new Date().getTime() - lastToast) > 10000 )
				{
					lastToast = new Date().getTime();
					showError(toast, "Can't get information from server. Please check network.", { position: "topRight" });
				}
			}

        }
        if ( error.response.status === 401)
        {
            //alert(error.response.data);
            console.log(error.response.data);
            sessionStorage.isExpired = true;
            router.replace("/logout");
        }
    }
    return Promise.reject(error);
  });


sessionStorage.refresh = 1;
sessionStorage.error = 0;
sessionStorage.inRefresh = 0;
export function isLoggedIn()
{
    if ( sessionStorage.token  )
    {
      return true;
    }
    return false;
}
export function myLocale()
{
    if ( sessionStorage.locale && sessionStorage.locale)
    {
        return sessionStorage.locale.replace("_", "-");
    }
    return sessionStorage.locale;
}
export function testServer(server, port)
{
	let testDefer = new Promise(function (resolve, reject) {
		
		if ( HTTPTEST )
	    {
	    	HTTPTEST.defaults.baseURL = protocol+server+':'+port+defaultAPI;
	    	HTTPTEST.post( serviceAPI+"/getServername/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	                .then( response => {
							resolve(response.data)
					})
					.catch( e => { 
						if ( toast )
						{
							//showError(toast, e, { position: "topRight" });
							
						}
						reject();
					});
		}
		else
		{
			resolve(null);
		}
	});
	return testDefer;
}
export function getServerInfo()
{
	//sessionStorage.port = port_LB;
	rebuildAPI();
	//console.log( HTTP.defaults.baseURL)
	
	if ( sessionStorage.port == port_LB )
	{
		let testDefer = new Promise(function (resolve, reject) {
		    	HTTP.post( serviceAPI+"/getService/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
		                .then( response => {
								//alert( JSON.stringify(response.data))
								resolve(response.data)
						})
						.catch( e => { 
							if ( toast )
							{
								showError(toast, e, { position: "topRight" });
							}
							reject();
						});
	
		});
		return testDefer;
	}
	else
	{
		let testDefer = new Promise(function (resolve, reject) {
			resolve( {name: "NX"+sessionStorage.port, port: sessionStorage.port})
		});
		return testDefer;
	}
	
}
export function getAvailableServers()
{
	let serverDefer = new Promise(function (resolve, reject) {
		HTTP.get( serviceAPI+"/getAvailableServers/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/0/"+sessionStorage.userId)
	    .then( response => {
			    //alert( JSON.stringify(response.data))
				let available = response.data.filter( p=>p.selectable );
				sessionStorage.servers = JSON.stringify(available);
				resolve( available);
				
			 }).catch( ex => { reject(); });
	  });
	 return serverDefer;
}
export function xApi( protocol, server, currentPort)
{
	if ( fixedPort)
	{
		HTTPAPI.defaults.baseURL = protocolConst+serverConst+':'+portConst+defaultAPI;
		return HTTPAPI;
	}
	HTTPAPI.defaults.baseURL = protocol+"://"+server+':'+currentPort+defaultAPI;
	return HTTPAPI;
}
function receiveToken( xToken, callback )
  {
	  //console.log("receiveToken")
	  initEnvironment().then( env => {
		  //console.log("receivingToken ")
	      if ( sessionStorage.inRefresh == 0)
	      {
	        sessionStorage.inRefresh = 1;
            //alert(authAPI+"/decode/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId + xToken);
            HTTP.post( authAPI+"/decode/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId, { token: xToken })
            //alert(authAPI+"/decode?tenantId="+sessionStorage.tenantId+"&accessPointId="+sessionStorage.accessPointId+"&token="+xToken);
            //HTTP.get( authAPI+"/decode?tenantId="+sessionStorage.tenantId+"&accessPointId="+sessionStorage.accessPointId+"&token="+xToken)
                .then( response => {
                        //alert("OK");
                        myLogin = response.data;
                        //console.log( "==================================================")
						//console.log( JSON.stringify( myLogin))
                        sessionStorage.isExpired = false;
                        sessionStorage.token = response.data.token;
                        
                        sessionStorage.myLogin = JSON.stringify(response.data);
                        sessionStorage.Xtoken = md5(sessionStorage.myLogin);
                        sessionStorage.refresh++;
                        sessionStorage.ip = response.data.ip; 
                        sessionStorage.isAdmin = response.data.isAdmin;
                        sessionStorage.salesUnitId = response.data.salesUnitId;
                        //let token = response.data.token;
                        sessionStorage.tenant = response.data.tenant;
                        sessionStorage.expiresIn = response.data.expiresIn;
                        sessionStorage.expiration = response.data.expiration;
                        sessionStorage.maxNumberOfRefresh = response.data.maxNumberOfRefresh;
                        sessionStorage.tenantId = response.data.tenantID;
                        
                        sessionStorage.accessPointId = response.data.accessPointID;
                        sessionStorage.userId = response.data.userID;
                        sessionStorage.login = response.data.login;
                        //sessionStorage.isAdmin = (response.data.isAdmin === true);
                        sessionStorage.userToken = xToken;
						
                        sessionStorage.served = sessionStorage.served || "initial";

                        if ( callback )
                        {
							//alert(1) 
                            //alert("HTTP.get("+ accessPointAPI+"/protected/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.accessPointId);
                            getAvailableServers().then( p => {} ); // can be done in parallel
                            
                            HTTP.get( accessPointAPI+"/protected/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.accessPointId)
                            //  , {
                            //  		headers: {
                            //  			'Authorization': 'Bearer '+ token,
                            //  			'X-Custom-Header': sessionStorage.userToken
                            //  		}})
                                .then( response => {
	                                let accessPoint = response.data.accessPoint;
	                                let tenant = response.data.tenant;
	                                let user = response.data.user;
	                                let data = response.data;
	                                
	                                sessionStorage.tenantInvAPI = tenant.invoiceAPI;
	                                sessionStorage.tenantInvExpAPI = tenant.invoiceExportAPI;
									sessionStorage.playlistAPI = tenant.playlistAPI;
	                                invExp = sessionStorage.tenantInvExpAPI;
	                                invoiceAPI = sessionStorage.tenantInvAPI;
									PlayListAPI = sessionStorage.playlistAPI;
	                                if ( tenant.invoiceView)
	                                {
	                                	invoiceView = tenant.invoiceView;
	                                }
                                    store.dispatch("setAccessPoint", accessPoint);
                                   
                                    try
                                    {
                                        if ( data.currency )
                                        {
                                            sessionStorage.hCurrency = data.currency.shortname;
                                            sessionStorage.hCurrencyId = data.currency.id;
                                        }
                                        else
                                        {
                                            sessionStorage.hCurrency = "ND";
                                            sessionStorage.hCurrencyId = -1;
                                        }
                                        if ( response.data.timezone )
                                        {
                                            sessionStorage.timezone = response.data.timezone.linked;
                                            sessionStorage.timezoneShort = response.data.timezone.shortname;
                                        }
                                        else{
                                            sessionStorage.timezone = "GMT";
                                            sessionStorage.timezoneShort =  "GMT";
                                        }
                                    }
                                    catch (e)
                                    {
                                         sessionStorage.timezone = "GMT";
                                         sessionStorage.timezoneShort =  "GMT";
                                    }
                                    sessionStorage.server = server;
                                    sessionStorage.port = port;
                                    if (response.data.selectedNode && response.data.selectedNode.id)
                                    {
										sessionStorage.server = response.data.selectedNode.server;
										sessionStorage.port = response.data.selectedNode.port;
									}
									if ( fixedPort)
									{
										sessionStorage.port = portConst;
									}
									
									//alert(response.data.selectedNode.name+"/"+response.data.nodes.length)
									rebuildAPI();
                                    sessionStorage.firstScreen = user.firstScreenValue;
                                    
                                    if ( user && user.favorites)
                                    {
                                    	setDarkMode( user.favorites.darkMode);
                                    }
                                    sessionStorage.locale = accessPoint.locale;
                                    sessionStorage.timezoneId = accessPoint.timezone;
                                    sessionStorage.dateFormat = accessPoint.dateFormat;
                                    sessionStorage.timeFormat = accessPoint.timeFormat;
                                    sessionStorage.weekStart  = accessPoint.weekStartsWith;
                                    sessionStorage.weekdays = JSON.stringify(accessPoint.weekdays);
                                    initiateRefresh( (sessionStorage.expiresIn*1000)-data.startRefreshBefore, data.displayRefresh);
                                    if (callback)
                                    {
                                        //console.log(">>>>>>>>"+accessPoint.tenantId+"<<<<<<<<");
                                        callback(accessPoint.tenantId);
                                    }
                                    sessionStorage.inRefresh = 0;
                                    //showRefresh();
                                }).catch(e => {
                                    console.log(e);
                                    sessionStorage.inRefresh = 0;
                                });
                        }
                        else
                        {
                            sessionStorage.inRefresh = 0;
                            initiateRefresh( (sessionStorage.expiresIn*1000)-response.data.startRefreshBefore, response.data.displayRefresh);
                        }
                      // console.log(this.myuser);
                      // console.log(sessionStorage.token);
					 
                }).catch(e => {
					console.log(JSON.stringify( e))
              });
				  

			
	    }
	    else
	    {
	        //console.log("in refresh was != 0 -> refresh() -> " + 2000);
	        initiateRefresh( 2000, 1000);
	    }
      });   
  }
	  


export function getSalesUnit(id, selectedId, options, selected, callback, fullData)
{
    sessionStorage.unitId = id;
	//console.log("getSalesUnit")
	
	let promise = new Promise((resolve, reject) => {
			initEnvironment().then( env => {
			    HTTP.get( fwAPI+"/salesUnit/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+id)
			        .then( response => {
			            //console.log("IN refresh("+n+")");
			            //alert("is ok")
			            sessionStorage.salesUnit = response.data.data.name; 
			            if ( selected && selected.id )
			            {
							if ( selected.id == id )
				            {
				                Object.assign( selected, response.data.selected); 
				            }
				            else
				            {
				                Object.assign( selected, response.data.all.find(p=>p.id==selected.id)); 
				            } 
				        }
			            else
						{
				            if ( !selectedId || selectedId == id )
				            {
				                Object.assign( selected, response.data.selected); 
				            }
				            else
				            {
				                Object.assign( selected, response.data.all.find(p=>p.id==selectedId)); 
				            }
			            } 
			            for (let i in response.data.all)
				        {
				            options.push( response.data.all[i]);
				        }
				        if ( fullData && Array.isArray( fullData ) )
				        {
							for (let i in response.data.fullData)
					        {
					            fullData.push( response.data.fullData[i]);
					        }
						}
						
			            //console.log( "selected SALESUNIT " + JSON.stringify(selected));
			            if ( callback )
			            {
				            // console.log( "calling callback()");
							callback();
						}
			            resolve( options);
			        }).catch(e => {
				        if ( e.response.data )
				        {
			            	alert("loading data for SalesUnit: " + e.response.data);
			            }
			            else
			            {
							alert("loading data for SalesUnit: " + e.response);
						}
						reject();
				});
			}) ;
		});
	return promise;
	
}

export function formatNumber(val) 
{
    if ( isNaN( val ) || val === null)
    {
    	return val;
    }
    if ( Number.isInteger( val ))
    {
    	var value = new Number(val).toLocaleString(
  	    myLocale(), // leave undefined to use the visitor's browser 
        // locale or a string like 'en-US' to override it.
        { minimumFractionDigits: 0 }	
		);
		return value;
    }
     	
 	value = new Number(val.toFixed(2)).toLocaleString(
	myLocale(), // leave undefined to use the visitor's browser 
      // locale or a string like 'en-US' to override it.
	{ minimumFractionDigits: 2 }
	);

    return value;
}

function refresh(n)
    {
        try{
	        while (myTimer.length > 0) {  
	        	clearInterval(myTimer.pop());
	        } 
        }
        catch(e) {
            console.log(e);
        }
        if ( ! n )
        {
            n = 0;
        }
        //console.log("wsTickerWebsocket.readyState() -> " + (wsTickerWebsocket ? wsTickerWebsocket.readyState: "??"));
        if ( wsTickerWebsocket && wsTickerWebsocket.readyState && tickerDisplay && wsTickerWebsocket.readyState == 3)
        {
           tickerDisplay( "refresh postponed...", "msgError");
           //console.log("refresh() -> " + 2000);
           //wsTickerWebsocket.close(); 
           //startTicker(tickerDisplay)
           initiateRefresh( 2000, 1000);
        }
        else
        {
           // alert( server+port+authAPI)
           // console.log("BFR refresh("+n+") - " + myTimer.length);
			initEnvironment().then( env => {
	            rebuildAPI();
				//console.log( APIURL)
				//console.log( sessionStorage.protocol+"/"+ APIURL +"/"+ HTTP.defaults.baseURL)
				let oldStruct = { userToken: sessionStorage.myLogin, node: sessionStorage.served};
				//console.log( JSON.stringify( oldStruct));
	            //console.log(authAPI+"/protected/refresh/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.accessPointId);
	            HTTP.post( authAPI+"/protected/refreshToken/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.accessPointId+"/"+n, oldStruct)
	                .then( response => {
	                    //console.log("IN refresh("+n+")");
	                    sessionStorage.userToken = response.data.userToken;
	                    sessionStorage.APIURL = APIURL;
						sessionStorage.served = response.data.node;
	                    sessionStorage.myLogin = JSON.stringify(response.data);
						
	                    //console.log("BFR receiveToken("+n+")::" + response.data.node);
	                    receiveToken( sessionStorage.userToken);     
	                    //alert( sessionStorage.userToken) 
	                }).catch(e => {
						 if ( e.message !== 'Network Error')
						 {
						 	if ( confirm ('Session (token) expired. logout?'))
						 	{
								clearInterval(displayTimer.pop());
			                 	$("#showLoginHere").html("");
			                 	router.replace("/logout");	
						 	}
						 } 
			             console.log(e);
			             console.log("refresh() -> " + 2000);
			             initiateRefresh( 2000, 1000);
	             
	            }) ;
			});
        }
    }
const zeroPad = (num, places) => String(num).padStart(places, '0')
function printTime( timeInSec )
{
	if ( !timeInSec )
	{
		return 0;
	}
	if ( timeInSec < 60 )
	{
		return timeInSec+'"';
	}
	if ( timeInSec < 3600 )
	{
		let min = Math.floor(timeInSec/60);
		let sec = timeInSec % 60;
		return zeroPad(min,2)+":"+zeroPad(sec,2);
	}
	if ( timeInSec )
	{
		let hh = Math.floor(timeInSec/3600);
		let min = Math.floor((timeInSec-hh*3600)/60);
		let sec = timeInSec % 60;
		return zeroPad(hh,2)+":"+zeroPad(min,2)+":"+zeroPad(sec,2);
	}
	
}
function showRefresh()
{
    var diff = sessionStorage.expiration - (new Date().getTime());
    diff = Math.round(diff / 1000);
    if ( diff < 0 )
    {
        if ( ! sessionStorage.token && displayTimer.length > 0)
         {
            clearInterval(displayTimer.pop());
            $("#showLoginHere").html("");
            router.replace("/logout");
        }
        return;
    }
    //console.log(diff+"/"+sessionStorage.maxNumberOfRefresh);
    var nr = sessionStorage.refresh;
    //console.log("nr = " +nr);
    var x = md5(sessionStorage.myLogin);
    var chk = (x == sessionStorage.Xtoken);
    if (! chk )
    {
        if ( sessionStorage.inRefresh == 0 && sessionStorage.refresh === nr )
        {
            //console.log("nr = " +sessionStorage.refresh+"/"+nr);
            sessionStorage.error++;
            refresh(2);
        }
    }
    let time = printTime( diff);
    let msg = "Token ("+sessionStorage.served+") expires in "+time+" ("+sessionStorage.maxNumberOfRefresh+")";
    $("#showLoginHere").html(msg);
    if ( sessionStorage.maxNumberOfRefresh <= 0 && diff < 120 )
    {
        $("#showUserHere").addClass("userInfoRed");
        $("#showLoginHere").addClass("userInfoRed");
    }
    if ( sessionStorage.maxNumberOfRefresh <= 0 && diff <= 0 )
    {
        router.replace("/logout");
    }
    $("#showUserHere").html(sessionStorage.login+" ["+sessionStorage.tenant+"/"+sessionStorage.timezoneShort+"]");
    if ( ! sessionStorage.token && displayTimer.length > 0)
    {
        clearInterval(displayTimer.pop());
        $("#showLoginHere").html("");
        router.replace("/logout");
    }
    if ( wsTickerWebsocket && wsTickerWebsocket.readyState && tickerDisplay)
    {
        if ( wsTickerWebsocket.readyState == 3 && sessionStorage.token )
        {
            tickerDisplay("["+sessionStorage.port+"] network issue (server not responding)... ", "msgError")
            if ( sessionStorage.port == port_LB )
            {
				sessionStorage.port = port_COMM;
				
				initEnvironment().then( env => {
					if ( fixedPort)
					{
						sessionStorage.port = portConst;
					}
					rebuildAPI();
				});
			}
            //wsTickerWebsocket.close();
            startTicker( tickerDisplay );
        }
    }
    //else if (tickerDisplay)
    //{
	//	startTicker( tickerDisplay );
	//}
}
function initiateRefresh( tokenRefresh, displayRefresh )
      {
        tokenTimerStarted = true;
       // console.log("refresh() -> " + tokenRefresh+"/"+sessionStorage.maxNumberOfRefresh+"/"+myTimer.length+"/"+displayTimer.length);
       try
       {
        if ( tokenRefresh > 0 && sessionStorage.maxNumberOfRefresh >= 0)
        {
            if ( displayTimer.length == 0 )
            {
                displayTimer.push( setInterval(showRefresh, displayRefresh));
            }
            if ( tokenRefresh > 0 && sessionStorage.maxNumberOfRefresh > 0)
            {
                if ( myTimer.length == 0 )
                {
                    myTimer.push( setInterval(refresh, tokenRefresh));
                }
            }
        }
        else
        {
			//logout();
		}
		} catch(e)
		{
			alert( "initiateRefresh" + JSON.stringify(e));	
		}
      }    
      
export var wsTickerWebsocket;
export var wsTickerWebsocketA;
export var wsTickerWebsocketB;
export var myWSID = null;
export var httpRequests = 0;
export var lastRequest = 0;

function getBrowserName()
{
    var sBrowser, sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
    //"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome, UR or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        sBrowser = sUsrAg;
    }
    return sBrowser;
}
function onWSTickerOpen(evt) 
{ 
    //console.log( "OPENING TICKER> " + JSON.stringify(evt));   
    if ( sessionStorage.token )
    {
		console.log("myTimer: " + myTimer.length)
    	initiateRefresh( 2000, 1000);   
    }
}
function onWSTickerClose(evt) 
{ 
    //console.log( "CLOSING TICKER> " + JSON.stringify(evt));  
}
function onWSTickerError(evt) { 
    console.log( "CLOSING TICKER> " + JSON.stringify(evt));
    wsTickerWebsocket.close(); 
}
export function print(msg)
{
    if ( isLoggedIn() && tickerDisplay )
    {
        tickerDisplay( msg, "msgInfo");
    }
}
export function error(msg)
{
    if ( isLoggedIn() && tickerDisplay )
    {
        tickerDisplay( msg, "msgError");
    }
}
var wsPayloadListener;
export function setWSPayloadListener(fctWSPayloadListener)
{
    wsPayloadListener = fctWSPayloadListener;
}
var toast;
export function setToast(t)
{
	toast = t;
}
var notyWidgetReload = null;
export function setNotyWidgetReload(fn)
{
	notyWidgetReload = fn;
}
var logsWidgetReload = null;
export function setLogsWidgetReload(fn)
{
	logsWidgetReload = fn;
}
var errorLogsWidgetReload = null;
export function setErrorLogsWidgetReload(fn)
{
	errorLogsWidgetReload = fn;
}
var appServerListener;
export function setAppServerListener(fctAppServerListener)
{
    appServerListener = fctAppServerListener;
}
function onWSTickerMessage(evt) 
{
    var data = JSON.parse(evt.data);
    //console.log( JSON.stringify(data));
    myWSID = data.myId;
    if ( data.state == 'START' )
    {
        if (    !sessionStorage.dbcnt 
             || ! sessionStorage.serviceStarted 
             || ! sessionStorage.serviceVersion
             || (sessionStorage.dbcnt > data.dbcnt) 
             || ! (sessionStorage.serviceVersion === data.serviceVersion))
        {
			if ( appServerListener )
            {
                appServerListener("restarted");
            }
        }
        sessionStorage.serviceStarted = data.serviceStarted;
        sessionStorage.builtDate = data.builtDate;
        sessionStorage.dbcnt = data.dbcnt;
        sessionStorage.serviceVersion = data.serviceVersion;
        const request = JSON.stringify( {
            className: "WSInit",
            tenantId: sessionStorage.tenantId, 
            jsonPayload: JSON.stringify( {
            tenantId: sessionStorage.tenantId, 
            accessPointId: sessionStorage.accessPointId, 
            userId: sessionStorage.userId, 
            browser: getBrowserName(),
            server: sessionStorage.server+":"+sessionStorage.port,
            ip: sessionStorage.ip, 
            token: sessionStorage.userToken})});
        
        wsTickerWebsocket.send(request);
    }
    else if ( data.state == 'END')
    {
        wsTickerWebsocket.close();
    }
    else
    {
		//console.log( "WS-> "+data.type + " " + data.state+ " " + data.payload)
        if ( data.type == 'REQUEST')
        {
            if ( data.info =='LOGOUT' && isLoggedIn() && tickerDisplay )
            {
                router.replace("/logout");
            }
            
        }
        else if ( data.type == 'ERRORLOG' && data.state == 'ERROR')
        {
			if ( toast )
			{
				toast.error(data.info, data.state, { position: "topRight" });
			}
			if ( errorLogsWidgetReload && data.payload)
		    {
				errorLogsWidgetReload(data.payload)
			}
		}
        else if ( data.type == 'LOG')
        {
			if ( logsWidgetReload && data.payload)
		    {
				logsWidgetReload(data.payload)
			}
		}
        else if ( data.type == 'TOAST')
        {
			if ( notyWidgetReload && data.payload )
		    {	
				notyWidgetReload(data.payload)
			}
            if ( toast )
            {
	            if ( data.state == 'OK' )
	            {
					toast.success(data.info, data.state, { position: "topRight" });
				}
				else if ( data.state == 'ERROR' )
	            {
					toast.error(data.info, data.state, { position: "topRight" });
				}
				else if ( data.state == 'WARNING' ) 
	            {
					toast.warning(data.info, data.state, { position: "topRight" });
				}
				else 
	            {
					toast.info(data.info, data.state, { position: "topRight" });
				}	
			}
        }
        else if ( data.type == 'PING')
        {
            if ( isLoggedIn() && tickerDisplay )
            {
                tickerDisplay( data.info+" --> "+data.fromId, "msgInfo");
                var info = JSON.parse(sessionStorage.myLogin);
                info.token = sessionStorage.token;
                info.oidUri = router.currentRoute.name;
                // OLD
                // HTTP.post( tickerAPI+"/protected/reply/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+data.fromId+"/"+myWSID, info)
                //     .then( response => {
                //     console.log("----> "+JSON.stringify( response.data));
                //     //alert("Ping send to " + user.wsID) 
                //     }).catch(e => {
                //         console.log("ERR----> "+JSON.stringify( e));
                // });
                // NEW
                var diff = sessionStorage.expiration - (new Date().getTime());
                diff = Math.round(diff / 1000);
                const request = JSON.stringify( {
                    className: "WSPingResponse",
                    tenantId: sessionStorage.tenantId, 
                    jsonPayload: JSON.stringify( {
                    toId: data.fromId, 
                    noOfRefresh: sessionStorage.maxNumberOfRefresh,
                    page: router.currentRoute.fullPath, 
                    url: router.currentRoute.name, 
                    server: sessionStorage.server+":"+sessionStorage.port,
                    expires: diff,
                    requestTime: data.time,
                    lastRequest: lastRequest, 
                    requests: httpRequests})});
                
                wsTickerWebsocket.send(request);
            }  
        }
        else
        {
            if ( isLoggedIn() && tickerDisplay )
            {
                
                tickerDisplay( data.fromServer+"> " + data.info, "msg"+data.type);
                if ( data.payload )
                {
                    if ( wsPayloadListener )
                    {
                        try 
                        {
                            wsPayloadListener( data.payload );
                        } catch (e)
                        {
                            console.log(e);
                        }
                    }
                }
            }
        }
    }
}


var listener = null;
export function setListener(l)
{
	listener = l;
}
export function unregisterListener(l)
{
	listener = null;
}
export function listen( module, title )
{
	if ( listener )
	{
		return listener( module, title );
	}
	return module;
}

var reloadFunction = new Array();
export function setReload(myReload)
{
	if (! myReload)
	{
		reloadFunction = new Array();
	}
	else
	{
		if ( !reloadFunction.includes(myReload))
		{
    		reloadFunction.push( myReload);
		}
	}
}
export function unregisterReload(myReload)
{
	if (myReload)
	{
		if ( reloadFunction.includes(myReload))
		{
			let reloadFunctionTMP = new Array();
			for ( let i in reloadFunction)
			{
    			if ( reloadFunction[i] !== myReload )
    			{
					reloadFunctionTMP.push( reloadFunction[i]);
				}
    		}
    		reloadFunction = reloadFunctionTMP;
    		//alert( reloadFunction.length)
		}
	}
}
export function reload(unitId)
{
    if ( reloadFunction && reloadFunction.length )
    {
		for ( let fct in reloadFunction)
		{
			try
			{
        		reloadFunction[fct](unitId);
        		
    		} catch (e)
    		{
				//
			}
		}
    }
}

var tickerDisplay = null; // defined in MenuBar.vué
export function startTicker(display)
{
    tickerDisplay = display;
    if ( wsTickerWebsocket && wsTickerWebsocket.readyState && wsTickerWebsocket.readyState < 2 )
    {
        console.log("wsTickerWebsocket " +wsTickerWebsocket.url +" already alive");
        return;
    }

	let WSNEWSURL = sessionStorage.WSURLMAIN?(sessionStorage.WSURLMAIN+'/wsNews/v1') : (wsConst+server+':'+portWS+'/wsNews/v1');
	//console.log(WSNEWSURL)
    wsTickerWebsocket = new WebSocket(WSNEWSURL);
    
    wsTickerWebsocket.onopen = onWSTickerOpen;
    wsTickerWebsocket.onclose = onWSTickerClose;
    wsTickerWebsocket.onmessage = onWSTickerMessage;
    wsTickerWebsocket.onerror = onWSTickerError;
    //alert(JSON.stringify(wsTickerWebsocket.url));	   
}

export function reStartTicker(display, newWSURL)
{ 
    tickerDisplay = display;
    if ( wsTickerWebsocket && wsTickerWebsocket.readyState && wsTickerWebsocket.readyState < 2 )
    {
	    console.log("closing wsTickerWebsocket ("+wsTickerWebsocket.url+")>" + wsTickerWebsocket.readyState)
        wsTickerWebsocket.close(); 
        console.log("closed wsTickerWebsocket>" + wsTickerWebsocket.readyState)
    }
    wsTickerWebsocket = new WebSocket(newWSURL);
    console.log("Restart> " + newWSURL)
    wsTickerWebsocket.onopen = onWSTickerOpen;
    wsTickerWebsocket.onclose = onWSTickerClose;
    wsTickerWebsocket.onmessage = onWSTickerMessage;
    wsTickerWebsocket.onerror = onWSTickerError;
    //alert(JSON.stringify(wsTickerWebsocket.url));	   
}


export { initiateRefresh, refresh, receiveToken };

if ( !tokenTimerStarted && isLoggedIn() && myTimer.length <= 0)
{
    refresh(1)
}
export function isAdmin()
{
       var data = JSON.parse(sessionStorage.myLogin);
   
      if (true === data.isAdmin)
      {
          return true;
      }
      return false;
}
export function logout()
{ 
    while ( myTimer.length > 0 )
    {
        clearInterval(myTimer.pop());
    }
    while ( displayTimer.length > 0 )
    {
        clearInterval(displayTimer.pop());
    }
    if (wsTickerWebsocket) { wsTickerWebsocket.close() }
    sessionStorage.myLogin = JSON.stringify({login:"", tenant:""});
    sessionStorage.removeItem('token'); 
    sessionStorage.removeItem('serviceVersion');


    try
    {
        if (wsTickerWebsocket) { wsTickerWebsocket.close() }
    } catch (e)
    {
        //
    }
}

export function showError(toast, text, e)
{
	let txt = text?text:"Unexpected error occured"
	if ( e.response)
    {
    	toast.error(txt+": " + e.response.data, 'Error', { position: "topRight" });
 	}
 	else
 	{
 		toast.error(txt+": " + e, 'Error', { position: "topRight" });
 	}
}
