 <template>
  <div 
  class="modal fade modal-mask"
    :style='getModalStyle()'
    id="fwEditModal"
    tabindex="1"
    :z-index="zIndex"
    role="dialog"
    aria-labelledby="fwEditModal"
    aria-hidden="true"
  >
   <div class="modal-wrapper">
     <div class="modal-container shadow" :style='getAppStyle()+getContentStyle()'>
         <div class='appHead'>
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'> </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='maximize()' aria-label="Maximize">
              <span aria-hidden="true">
              <svg v-show='width != 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
				  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
			  </svg>
              <svg v-show='width == 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
				  <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
			   </svg>
              </span>
              
            </button>
            <button id='closePopup2' type="button" class="iButton" @click='closeUntilPosition(-1)' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
            </button>
            </div>
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft' :style="getAppStyle()+'color: red;'">
            	{{metadata.name}} 
                </div>
                <div class='appRight' style='color: red;'>
                EDITOR
                </div>
            </div>
            
           </slot>
        </div>
        <div class="modal-body" :style='getMainContentStyle()' >
          <slot name="body">
            <div class='mainContent transition' >
            
            <tabs style='height: 92%;' size="small" layout='top' animation="slide" :selectedIndex="selectedTab"  @tab-selected='tabChange'>
            <tab-pane v-for='(tab,tabIdx) in folderDefinition' 
                      :key="'t_'+tabIdx" :label="tab.display" 
                      :selected="selectFirstTab(tab, tabIdx)"    
                      @contextmenu="menuRClicked($event, tab, tabIdx)"                  
                      >
                   
            <table width='100%' class='transition editTable'>
            	<tr v-for='(row,rowIdx) in tab.rows' :key="'tr_'+tabIdx+'_'+rowIdx">
            		<td v-for='(col,colIdx) in row' :key="'td_'+tabIdx+'_'+rowIdx+'_'+colIdx" :colspan='col.span' style='margin: 0pt;'
            		>
            		
            			<div 
            			   :id="'c_'+tabIdx+'_'+rowIdx+'_'+colIdx"
		                   @dragstart.exact="dragStart($event, tabIdx, rowIdx, colIdx)"
		                   @dragend.exact="dragEnd($event, tabIdx, rowIdx, colIdx)" 
		                   @dragover.prevent
						   @dragenter.prevent
		                   @drop.exact="dropColumn($event, tabIdx, rowIdx, colIdx)"
		                   @contextmenu="menuRClicked($event, tab, tabIdx, col)"
		                   class='editorElement transition' draggable :style="getAppStyle()+getStyleX(tab, col)">
		                
		                  <span v-if="visible(col.data) && !isLink(col.data)" 
		                       @click="nodeToOpen($event, 'Columns', findColumn(col.name))"
		                       :class='getLabelClass(tab, col)'  :style="getLabelStyleX(tab, col)" >
		                      <span v-if="col.data.tooltip" v-tooltip="toolTip(tab, col)">{{ getDisplay(col) }}</span>
		                      <span v-else>{{ getDisplay(col) }}</span>
		                  </span>
		                  <span class='elementField' :style="getFieldStyleX(tab,col)">
		                  	
		                 	  <hr v-if="col.name === 'separator'" class='separator'/>
		                 	  <div v-else-if="col.name === 'label'">
		                 	  	<InputText @enter="update()" v-model="col.label"/>
		                 	  </div>
		                 	  <InventoryFilterEditor  v-else-if="isInventoryFilter(col.data)"
		                 	  	v-model="data[ col.name]"
		                 	  	:columnName="col.name"
		                 	  	:parentRecord="data"
		                 	  	@open="openInvEditor"
		                 	  >
		                 	  </InventoryFilterEditor>
		                 	  <InputJSON v-else-if="isJSON(col.data)" 
			                            :mark=mark[col.name]
			                            @changed="useCopyFrom(col.name)"
			                            @enter="update()" :disabled="!col.data.editable" 
			                            v-model="data[ col.name]"/>
			                  <InputText v-else-if="isString(findColumn(col.name))" 
			                            :mark=mark[col.name]
			                            @changed="useCopyFrom(col.name)"
			                            @enter="update()" :disabled="!col.data.editable" 
			                            v-model="data[ col.name]"/>
			                     <InputDynDate v-else-if="isDynamicDate(col.data)" 
			                            :mark=mark[col.name]
			                            @changed="useCopyFrom(col.name)"
			                            @enter="update()" :disabled="!col.data.editable" 
			                            v-model="data[ col.name]"/>       
	                            <InputColumnHeader v-else-if="isColumnHeader(findColumn(col.name))" 
		                            :recordId=data.id
		                            :mark=mark[col.name]
		                            :entityMap=entityMap
		                            :column=get(col.name)
			                        :selectedTab=selectedTab
			                        :tabNo=tabIdx
			                        :definition="findColumn(col.name)"
			                        :useCache=false
		                            :disabled="!findColumn(col.name).editable" 
		                            v-model="data[ col.name]"/>
			                    <InputColour v-else-if="isColour(findColumn(col.name))" 
			                            :mark=mark[col.name]
			                            :disabled="!findColumn(col.name).editable" 
			                            v-model="data[ col.name]"/>
			                    <InputWeekdays v-else-if="isWeekday(findColumn(col.name))"  
			                            :disabled="!findColumn(col.name).editable" 
			                            v-model="data[ col.name]" :time="new Date().getTime()"/>
			                    <InputTime v-else-if="isTime(findColumn(col.name))"  
			                            :disabled="!findColumn(col.name).editable" 
			                            v-model="data[ col.name]" :time="new Date().getTime()"/>
			                    <InputText v-else-if="isInt(findColumn(col.name))" 
			                            :disabled="!findColumn(col.name).editable"
			                            @changed="useCopyFrom(col.name)"  
			                            v-model.number="data[ col.name]"/>     
			                    <InputInt v-else-if="false && isInt(findColumn(col.name))" 
			                            :disabled="!findColumn(col.name).editable"
			                            @changed="useCopyFrom(col.name)"  
			                            v-model.number="data[ col.name]"/>     
			                    <InputDouble v-else-if="isDouble(findColumn(col.name))" 
			                            @enter="update()" :disabled="!findColumn(col.name).editable"  
			                            :nulls="!findColumn(col.name).mandatory"
			                            v-model="data[ col.name]"/>     
			                    <InputDatePick v-else-if="isDate(findColumn(col.name))" 
			                            @enter="update()" :disabled="!findColumn(col.name).editable"  
			                            v-model="data[ col.name]"/>     
			                    <InputDateTime v-else-if="isDatetime(findColumn(col.name))" 
			                            @enter="update()" :disabled="!findColumn(col.name).editable"  
			                            v-model="data[ col.name]"/>     
			                    <app-switch v-else-if="isBool(findColumn(col.name))" :disabled="!findColumn(col.name).editable" 
			                            v-model="data[col.name]" :checked="data[ col.name]"/>     
			                    <InputSecret v-else-if="isSecret(findColumn(col.name))" 
			                            :disabled="!findColumn(col.name).editable"
			                            v-model="data[ col.name]"/>
			                    <GSelectModel v-else-if="isModel(findColumn(col.name))" 
			       						:visible="selectedTab == tabIdx"
			                        	:disabled="!findColumn(col.name).editable"
			                            :index=data.id :time="new Date().getTime()"
			                            v-model="data[ col.name]"/>
			                    
			                    <GImage v-else-if="isImg(findColumn(col.name))" :data="getImgData(data[col.name])"  />
			                    <div  v-else-if="isLink(col.data)" >
			                    	<b>Link:</b> {{col.name}}
				                    <GLink  
				                        :visible="selectedTab == tabIdx" :counter=updateCount
				                        
				                        :data=data :id=data.id :metadata=moduleMetadata :definition="moduleMetadata.links['Columns']" />
			                    </div>
			                    
			                    <GSelectM v-else-if="isMSelect(findColumn(col.name))" 
			                        :visible="selectedTab == tabIdx"
			                        :mark=mark[col.name]
			                        :disabled="!findColumn(col.name).editable"
			                        :useCache="false"
			                        :entityMap="entityMap"
			                        :parentMeta="getParentMeta()"
			    					
			                         v-model="data[ col.name]"		                      
			                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="findColumn(col.name)" />
			                    <GSelect v-else-if="isSelect(findColumn(col.name))" 
			                        :visible=true
			                        :column=get(col.name)
			                        :selectedTab=selectedTab
			                        :tabNo=parseInt(tabIdx)
			                        :mark=mark[col.name]
			                        :useCache=false
			                        :disabled="!findColumn(col.name).editable"
			                        :entityMap=entityMap
			                        :parentMeta="getParentMeta()"
			    				
			                         v-model="data[ col.name]"
			                         @open=openLink
			                         @input="onSelect(col.name)"
			                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="findColumn(col.name)" />
			                    
			                    <InputTimezone v-else-if="isTz(findColumn(col.name))" 
			                        :visible="selectedTab == tabIdx"
			                        :disabled="!findColumn(col.name).editable"
			                        :entityMap="entityMap"
			                         v-model="data[ col.name]"
			                         @open=openLink
			                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="findColumn(col.name)" />
			                    <GSelectM v-else-if="mightBeDynSelect(findColumn(col.name)) && isDynMSelect(findColumn(col.name))" 
			                        :visible="selectedTab == tabIdx"
			                        :mark=mark[col.name]
			                        :disabled="!findColumn(col.name).editable"
			                        :useCache="false"
			                        :entityMap="entityMap"
			                        :parentMeta="getParentMeta()"
			    					
			                         v-model="data[ col.name]"
			                         @open=openLink
			                         @input="onSelect(col.name)"
			                        :data=data :id=data.id :time=colIdx :metadata=metadata :definition="findColumn(col.name)" />
			                    <GSelect v-else-if="mightBeDynSelect(findColumn(col.name)) && isDynSelect(findColumn(col.name))" 
			                        :visible=true
			                        :selectedTab=selectedTab
			                        :tabNo=tabIdx
			                        :mark=mark[col.name]
			                        :useCache=false
			                        :disabled="!findColumn(col.name).editable"
			                        :entityMap=entityMap
			                        :parentMeta="getParentMeta()"
			    					
			                         v-model="data[ col.name]"
			                         @open=openLink
			                         @input="onSelect(col.name)"
			                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="findColumn(col.name)" />
			                    <vSelect v-else-if="mightBeDynSelect(findColumn(col.name)) && isDynDate(findColumn(col.name))" 
			                        :options="[{id: 'now()',label:'Now/Today'},{id: 'boy()',label:'Beginning of this year'}]" 
			                        v-model="data[ col.name]"
						            placeholder=''>  
						        </vSelect>
			                    <InputText v-else-if="mightBeDynSelect(findColumn(col.name))" 
			                            :mark=mark[col.name]
			                            @changed="useCopyFrom(col.name)"
			                            @enter="update()" :disabled="!findColumn(col.name).editable" 
			                            v-model="data[ col.name]"/>
			                    <div v-else style='border: 1px dotted #aaa; color: #aaa; height: 100% !important; width: 100% !important;'>[ empty ]</div>
		                   </span>
		                  </div>
            		</td>
            	</tr>
            </table>

              </tab-pane>
              </tabs>
              <table width='100%' >
              <tr>
              <td :class="msgClass">
                {{ message }} 
              </td>
              </tr>
              </table>
              </div>
              <div style='border-top: 1pt solid #bbbbff;'>
              <table width='100%'>
             
              <tr>
              <td width="20%" class='lUpdateInfo'>Last Update</td>
              <td><InputDateTime size="11pt" :disabled=true :value="new Date().getTime()" /></td>
              <td rowspan="2" width="20%" class='lUpdateInfoSuccess'><div v-show=success id='showSucess' >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#8c8" class="bi bi-check2" viewBox="0 0 16 16">
				  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
				</svg>
              </div></td>
              </tr>
              <tr>
              <td class='lUpdateInfo'>Last User</td><td class='lUpdateInfo'>&lt;last user&gt;</td>
                            
              </tr>
              </table>
            </div>
              
               <div class="modal-footer" style='width: 100%; height: 80pt; float: right;'>
               <div style='display: inline-flex; float: right;'>
                <button :style='getAppStyle()' type="button" class="button btn-secondary btn-close" @click="close()" data-dismiss="modal">Close</button>
                <button :style='getAppStyle()' type="button" class="button btn-secondary btn-reset"  @click.prevent="reset()">Reset</button>
                <button :style='getAppStyle()' type="button" class="button btn-primary btn-save" :disabled="!metadata.editable"  @click.prevent="update()">Save</button>
              </div>
               <div style='display: inline-flex; float: right; padding-top: 4pt; padding-right: 20pt;'>
                <button :style='getAppStyle()' type="button" class="button btn-nav" :disabled='true'>|&lt;</button>
                <button :style='getAppStyle()' type="button" class="button btn-nav" :disabled='true'>&lt;</button>
                <button :style='getAppStyle()' type="button" class="button btn-nav" :disabled='true'>&gt;</button>
                <button :style='getAppStyle()' type="button" class="button btn-nav" :disabled='true'>&gt;|</button>
              
               </div>
               <div style='display: inline-flex; float: right; padding-top: 4pt; padding-right: 20pt;'>
 
                <span v-if="metadata.extradata">
                <button  v-for="fds in moduleMetadata.functions" :disabled='changes > 0'  type="button" class="button btn-nav btn-editor"  
                       title='Create new column / changes must be saved before'
                       :style='getAppStyle()'
                       :key="moduleMetadata.name+'_'+fds.name"  @click.prevent="run(moduleMetadata, fds)">{{fds.display}}</button>
                </span>
               </div>
             </div>
         </slot>
      </div>
    </div>
    
  </div>        

   <ErrorPopUp :errorType="errorTypePU" :error="errorPU"></ErrorPopUp>
   
   <ContextMenu ref="menu">
      <template v-if="contextData" slot-scope="{ event, contextData }">
        <ContextMenuItem v-if="contextData.tab && contextData.tab.cells < 4" @clicked="addCellToTable(contextData.tab); $refs.menu.close()">
          add table column 
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.tab && contextData.tab.cells > 1" @clicked="removeCellFromTable(contextData.tab); $refs.menu.close()">
          remove last table column
        </ContextMenuItem>
         <ContextMenuItem v-if="contextData.col && contextData.col.data" @clicked="addEmptyRowBefore(contextData.event, contextData.tab, contextData.col);$refs.menu.close()">
          add empty row before
        </ContextMenuItem>
         <ContextMenuItem v-if="contextData.tab && contextData.tab.cells" @clicked="addEmptyRow(contextData.tab); $refs.menu.close()">
          add empty row at the end
        </ContextMenuItem>
        
        <ContextMenuSep/>
        <ContextMenuItem v-if="contextData.col && contextData.col.data && contextData.col.data.display" @clicked="removeColumn(contextData.tab, contextData.col); $refs.menu.close()">
          remove column '{{contextData.col.data.display }}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.col && contextData.col.data && contextData.col.data.references" @clicked="removeColumn(contextData.tab, contextData.col); $refs.menu.close()">
          remove link '{{contextData.col.data.name }}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.col && contextData.col.empty" @clicked="removeColumn(contextData.tab, contextData.col); $refs.menu.close()">
          remove empty column
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.tab && contextData.tab.display" @clicked="showAllColumns(contextData.event, contextData.tab);">
          add column to folder '{{contextData.tab.display }}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.col && contextData.col.data" @clicked="addEmptyColumn(contextData.event, contextData.tab, contextData.col);$refs.menu.close()">
          add empty column here
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if="contextData.col && contextData.col.span < contextData.tab.cells" @clicked="contextData.col.span+=1; change(); $refs.menu.close()">
          increase column span
        </ContextMenuItem>
        
        <ContextMenuItem v-if="contextData.col && contextData.col.span > 1" @clicked="contextData.col.span-=1; change(); $refs.menu.close()">
          decrease column span
        </ContextMenuItem>
        
        <ContextMenuSep/>
        <ContextMenuItem v-if="contextData.col && contextData.col.display" @clicked="nodeToOpen($event, 'Columns', contextData.col.data)">
          open column '{{contextData.col.display}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.tab && contextData.tab.id" @clicked="openFolderEditor($event, contextData.tab.id, selectedTab)">
          open folder '{{contextData.tab.display}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.tab && contextData.tab.id" @clicked="openModuleEditor(contextData.event)">
          open module '{{metadata.name}}'
        </ContextMenuItem>
       
       </template>
     </ContextMenu>


    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'Add column to ' + selectedFolder.display}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Add', btn1OnClick:closeSelectColumns, btn2OnClick:copy, disableBtn2:true}"
       name="modal-selectColumns"  @on-close="closeSelectColumns">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <div v-for="(col,idx) in allColumns" :key="'ac_'+idx">
          
          <div v-if="col.link" style='color: #008;' @click='addColumn(selectedFolder, col)' class='selector'>{{col.display}}<span style='font-size: 9pt; color: grey; padding-left: 8pt;'>{{ col.folder }}</span> (Link)</div>
          <div v-else @click='addColumn(selectedFolder, col)' class='selector'>{{col.display}}<span style='font-size: 9pt; color: grey; padding-left: 8pt;'>{{ col.folder }}</span></div>
        </div>
		</div>
   </vue-modal-2>
    
    <GFWEditorRouter v-if='metadataDetail.editor && showEditorDetail' @update="reset" @insert="insert" @close="closeEditorDetail" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadataDetail" 
    :dataId="recordId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter>
  </div>
</template>

<script>
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import {HTTP, fwAPI, showError, gridAPI } from '@/variables.js'
import GLink from '@/components/GLink';
import GSelect from '@/components/GSelect';
import GSelectM from '@/components/GSelectM';
import GSelectModel from '@/components/GSelectModel';
import InputText from '@/components/inputElements/InputText';
import InputColour from '@/components/inputElements/InputColour';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import InputDynDate from '@/components/inputElements/InputDynDate';
import GImage from '@/components/GImage';
import InputSecret from '@/components/inputElements/InputSecret';
import InputColumnHeader from '@/components/inputElements/InputColumnHeader';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InputDouble from '@/components/inputElements/InputDouble';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputDatePick from '@/components/inputElements/InputDatePick';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
import InputJSON from '@/components/inputElements/InputJSON';

import Switch from '@/components/Switch'
import ErrorPopUp from '@/components/ErrorPopUp';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { Tabs, TabPane } from 'vue-bulma-tabs'
import JQuery from "jquery";
let $ = JQuery;
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';

var timers = [];
const EMPTY_COL = { visible: true, editor: "String", display: ''};
const EMPTY_FIELD = { name: "", display: "", empty: true, visible: true, span: 1, data: EMPTY_COL };
const SEPARATOR_FIELD = { name: "separator", display: "", empty: true, visible: true, span: 1, data: EMPTY_COL };
const LABEL_FIELD = { name: "label", display: "", empty: true, visible: true, span: 1, data: EMPTY_COL };
export default {
  name: "GFWEditorEditor",
   components : {
    InventoryFilterEditor,
    ContextMenu, ContextMenuItem, ContextMenuSep, InputJSON,
    InputText, 'app-switch': Switch, InputSecret,InputDynDate,InputDateTime, InputDatePick, InputInt, Tabs, TabPane, InputTimezone, GImage,  GLink,
    GSelectM, GSelect, InputDouble, ErrorPopUp, InputTime, InputWeekdays, GSelectModel, InputColour, vSelect, InputColumnHeader
  },
  props: {
     metadata: Object,
     moduleData: Object,
     zIndex: { type: Number, default: 1}
  },
  data(){
    return {
        folderDefinition: [],
        allColumns: [],
        selectedFolder: {},
        allEntities: [],
        moduleMetadata: {},
        entityMap: [],
        mapping: [],
        selectedRecord: {},
        success: false,
        selectedIndex: 0,
        index: this.selectedIndex,
        creatables: [],
        dataRecord: {},
        changes: 0,
        data: {},
        recordId: 0,
        selectedTab: 0,
        visualMapping: Array,
        visualIndex: 0,
        numberOfRows: 0,
        lastUser: {},
        width: 70,
        height: 90,
        copyToColumn: [],
        mainHeight: 75,
        allDetailIDs: [],
        mark: [],
        selectedDetail: {},
        metadataDetail: {},
        detailCounter: 0,
        showEditorDetail: false,
        msgClass: "msgDefault",
        message: "",
        errorPU: {},
        errorTypePU: "",
        updateCount: 0,
        getAppStyle,
		currentDataRecord: this.data,
        currentParentId: 0,
        currentParentMeta: {}
     }
    },
  methods: {
        name() { return "FWViewer"+this.zIndex; },
        close() {this.selectedTab = 0; this.$emit('close')},
        copy(r) { let aCopy = {}; Object.assign( aCopy, r); return aCopy; },
        getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
        getMainContentStyle()  { return "width: 100%; height: "+ this.mainHeight+"% !important;"; },
        incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
        decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
        maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
        isString(column) { return column.editor == 'STRING'; },
        isTz(column) { return column.editor == 'SELECT' && column.visualDetail == 'TZ' && !column.array; },
        isLink(column) { return column && column.editor == 'LINK'; },
        isSelect(column) {
            let b = column.editor == 'SELECT' && column.visualDetail != 'TZ' && !column.array;
        	return b; 
        },
        isMSelect(column) { return column.editor == 'SELECT' && column.visualDetail != 'TZ' && column.array; },
        isInt(column) { return column.editor == 'INT'; },
        isDouble(column) { return column.editor == 'DECIMAL'; },
        isSecret(column) { return column.editor == 'SECRET'; },
        isColour(column) { return column.editor == 'COLOUR'; },
        isModel(column) { return column.editor == 'SELECTMODEL'; },
        isInventoryFilter(column) { return column.editor === 'INVENTORY'; },
        isColumnHeader(column) { return column.editor == 'COLUMNHEADER'; },
        isDynamicDate(column) { return column.editor === 'DYNDATE'; },
        isBool(column) { return column.editor == 'BOOLEAN'; },
        isTime(column) { return column.editor == 'TIME'; },
        isWeekday(column) { return column.editor == 'WEEKDAYS'; },
        isDate(column) { return column.editor == 'DATE'; },
        isDatetime(column) { return column.editor == 'DATETIME'; },
        isImg(column) { return column.editor == 'IMAGE';},
        mightBeDynSelect(column) { return (column.editor == 'VSELECT');},
        isDynMSelect(column) { let b = (column.editor == 'VSELECT' && this.getDynMLink(column)); return b },
        isDynSelect(column) { let b = (column.editor == 'VSELECT' && this.getDynLink(column)); return b },
        isDynDate(column) { let b = (column.editor == 'VSELECT' && this.getDynDates(column)); return b },
        isAdmin() {return sessionStorage.isAdmin == 'true'; },
        isJSON(column) { return column.type === 'json'; },
        dragStart(evt, tabIdx, rowIdx, colIdx)
        {
        	let field = 'c_'+tabIdx+'_'+rowIdx+'_'+colIdx;
        	$('#'+field).addClass('draggable');
        	//$('.editorElement').addClass('ondrag');
        	$('.editTable').attr('border', 1);
        	evt.dataTransfer.setData('dndType', 'move');
	        evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('rowIdx', rowIdx)
	        evt.dataTransfer.setData('colIdx', colIdx)
        },
        dropColumn(evt, tabIdx, rowIdx, colIdx)
        {
        	let rowIdxSrc = evt.dataTransfer.getData('rowIdx');
        	let colIdxSrc = evt.dataTransfer.getData('colIdx');
        	
        	let fld = this.metadata.folder[tabIdx]

        	let column = this.folderDefinition[tabIdx].rows[rowIdxSrc][colIdxSrc];
        	this.folderDefinition[tabIdx].rows[rowIdxSrc].splice(colIdxSrc, 1);
        	this.folderDefinition[tabIdx].rows[rowIdx].splice(colIdx, 0, column);
        	this.change();
        	
        	//alert( column.name );
        	/*
        	this.metadata.folder[tabIdx].columns.splice(idxSrc, 1);
        	this.metadata.folder[tabIdx].columns.splice(colIdx, 0, nameSrc);
        	this.change();
        	this.calcData();
        	this.$forceUpdate();
        	*/
        },
        change()
        {
            this.reCalcData();
        	this.changes++;
        },
        dragEnd(evt, tabIdx, rowIdx, colIdx)
        {
        	let field = 'c_'+tabIdx+'_'+rowIdx+'_'+colIdx;
        	$('#'+field).removeClass('draggable');
        	$('.editTable').removeAttr('border');
        },
        menuRClicked(event, tab, tabIdx, column)
	    {
	        event.preventDefault();
	        if ( column )
	        {
	        	this.$refs.menu.open(event, {tab: tab, col: column, event: event} )
	        }
	        else
	        {
	        	this.$refs.menu.open(event, {tab: tab, event: event} )
	        }
	    },
	    showAllColumns( event, tab)
	    {
	        
	        //alert(this.$refs.showColumns)
	        //alert(JSON.stringify(this.allColumns))
	        //event.preventDefault();
	        this.getAllColumns();
	        this.selectedFolder = tab;
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumns');
	    	
	    },
	    
	    closeSelectColumns()
	    {
	      	this.$vm2.close('modal-selectColumns');
	    },
	    nodeToOpen( event, name, node)
	    {
	        this.$refs.menu.close();
	        this.mapping = [ {row: 0, dataId: node.id, rId: 0, visible: true}];
	    	this.allEntities = [ node];
	    	this.selectedRecord = {};
	        Object.assign( this.selectedRecord, node);
			this.recordId = node.id;
	    	this.metadataDetail = {};
	    	//alert(metadata.name)
	    	let that = this;
	    	let time = new Date().getTime();
	    	let mapperLoad = new Promise(function (resolve, reject) {
		    
			
	    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/null/true/1", [node.id])
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		console.log("LOADED rights in " + dur +"ms");  
	                    that.allEntities = response.data.data;
	                    Object.assign( that.selectedRecord, that.allEntities[0]);
	                    that.metadataDetail = response.data.metadata;
	                    that.entityMap = response.data.entity2DataMap;
	                    that.showEditorDetail = true;
	                          
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    	});
	    	
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    },
	    openModuleEditor( event )
	    {
	    	
	        let name = 'Module';
	        let node = this.moduleData;
	        this.$refs.menu.close();
	        this.mapping = [ {row: 0, dataId: node.id, rId: 0, visible: true}];
	    	this.allEntities = [ node];
	    	this.selectedRecord = {};
	        Object.assign( this.selectedRecord, node);
			this.recordId = node.id;
	    	this.metadataDetail = {};
	    	
	    	let that = this;
	    	let mapperLoad = new Promise(function (resolve, reject) {

	    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/null/true/1", [node.id])
	        		 .then( response => 
	                 {
	                    that.allEntities = response.data.data;
	                    Object.assign( that.selectedRecord, that.allEntities[0]);
	                    that.metadataDetail = response.data.metadata;
	                    that.entityMap = response.data.entity2DataMap;
	                    that.showEditorDetail = true;
	                          
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    	});
	    	
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    },
	    openFolderEditor( event, id, idx )
	    {
	        let name = 'Folder';
	        let node = {id: id};
	        this.$refs.menu.close();
	        this.mapping = [ {row: 0, dataId: id, rId: 0, visible: true}];
	    	this.allEntities = [ node];
	    	this.selectedRecord = {};
	        Object.assign( this.selectedRecord, node);
			this.recordId = node.id;
	    	this.metadataDetail = {};
	    	//alert(metadata.name)
	    	let that = this;
	    	let time = new Date().getTime();
	    	let mapperLoad = new Promise(function (resolve, reject) {
		    
			
			console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/2022-02-02/true/1");
			console.log( JSON.stringify([node.id]));
	    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/null/true/1", [node.id])
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		console.log("LOADED rights in " + dur +"ms");  
	                    that.allEntities = response.data.data;
	                    Object.assign( that.selectedRecord, that.allEntities[0]);
	                    that.metadataDetail = response.data.metadata;
	                    that.entityMap = response.data.entity2DataMap;
	                    that.showEditorDetail = true;
	                          
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    	});
	    	
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    },
	    
	    reset()
	    {
	        let that = this;
	        //alert("reset");
            HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/definition")
                    .then( response => 
                    {
                    
			        	that.metadata = response.data.metadata;
			        	//Object.assign(that.metadataDetail, that.metadata); 
			        	that.metadata.editor = 'GFWEditorEditor';
			        	that.metadata.editable = true;
			        	that.changes=0; // reset changes
			        }).catch(e => {
                     that.error("can't open editor "+that.metadata.name, e);
                });
        },
        getMetaMeta()
	    {
	        let that = this;
	        //alert("getMetaMeta");
            HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/Module/definition")
                    .then( response => 
                    {
			        	that.moduleMetadata = response.data.metadata;
			        	//alert("getMetaMeta: "+that.moduleMetadata.name+ ":"+that.metadata.name);
			        	that.calcData();
			        	that.$forceUpdate();
			        }).catch(e => 
			        {
                     that.error("can't open editor "+that.metadata.name, e);
					 that.closeUntilPosition(-1);
                });
        },
        addColumn( tab, col)
	    {
	    	if ( col.name && col.name === SEPARATOR_FIELD.name )
	    	{
	    		let cell = {};
	    		Object.assign( cell, SEPARATOR_FIELD);
	    		cell.span = tab.cells;
	    		this.selectedFolder.rows.push([cell]);
	    	}
	    	else if ( col.name && col.name === LABEL_FIELD.name )
	    	{
	    		let cell = {};
	    		Object.assign( cell, LABEL_FIELD);
	    		cell.span = tab.cells;
	    		this.selectedFolder.rows.push([cell]);
	    	}
	    	else
	    	{
	    		let column = this.findColumn(col.name);
	    		this.selectedFolder.rows.push([{name: col.name, visible: true, empty: false, span: 1, data: column}]);
	    	}
	    },
        findFolder(column)
        {
        	try{
	        	let that = this;
	        	for ( let tab in that.folderDefinition)
	        	{
	        		let folder = that.folderDefinition[tab];
	        		let rows = folder.rows;
	        		for ( let rowIdx in rows )
	        		{
	        		 	let row = rows[rowIdx];
		        		for ( let col in row )
		        		{
		        			let cx = row[col].data;
		        			if ( column.column )
		        			{
		        				if ( !cx.empty && cx.column === column.column )
		        				{
		        					return folder;
		        				}
		        			}
		        			if ( column.name )
		        			{
		        				if ( !cx.empty && cx.column === column.name )
		        				{
		        					return folder;
		        				}
		        			}
		        		}
		        	}	
		        }
	        } catch (e) 
	        {
	        	//
	        }
	        return null;
        },
        getAllColumns()
        {
        	//
        	// folderDefinition MUST be set before
        	//
        	let that = this;
        	that.allColumns = new Array();
        	that.allColumns.push({name: SEPARATOR_FIELD.name, display: '<separator>', link: false});
        	that.allColumns.push({name: LABEL_FIELD.name, display: '<label>', link: false});
	        for (let c in that.metadata.columns)
	    	{
	    		let column = that.metadata.columns[c];
	    		if ( column.visible )
	    		{
		    		let folder = that.findFolder(column);
		    		if ( folder && folder.display)
		    		{
		    			that.allColumns.push({name: column.column, display: column.display, folder: folder.display, link: false});
		    		}
		    		else
		    		{
		    			that.allColumns.push({name: column.column, display: column.display, link: false});
		    		}
		    	}
	    	}
	    	
	    	for (let c in that.metadata.links)
	    	{
	    		let link = that.metadata.links[c];
	    		let folder = that.findFolder(link);
	    		//alert( JSON.stringify(link));
	    		if ( link.column !== 'id' || link.column === 'id')
	    		{
	    			that.allColumns.push({name: link.name, display: link.name, link: true});
	    		}
	    		
	    	}
	    	that.allColumns.sort((a, b) => {
			    let fa = a.display.toLowerCase(),
			        fb = b.display.toLowerCase();
			
			    if (fa < fb) {
			        return -1;
			    }
			    if (fa > fb) {
			        return 1;
			    }
			    return 0;
			});
        },
        clearStructure()
        {
        	for ( let f in this.folderDefinition )
        	{
	        	let rows = this.folderDefinition[f].rows;
	        	let tab = this.folderDefinition[f];
	        	let cleanRows = new Array();
	        	let lastRowEmpty = false;
	        	for ( let r in rows )
	        	{
	        		let firstRow = (cleanRows.length === 0);
	        		let row = rows[r];
	        		if ( row && row.length > 0 )
	        		{
	        			let cleanRow = new Array();
	        			let empty = true;
	        			for ( let c in row )
	        			{
	        		    	let col = row[c];
	        		    	if ( col.name === SEPARATOR_FIELD.name )
	        	 			{
	        	 				col.visible = true;
	        	 				col.display= '<separator>'
	     
	        					empty = false;
	        	 				cleanRow.push( col);
	        	 			}
	        	 			else if ( col.name === LABEL_FIELD.name )
	        	 			{
	        	 				col.visible = true;
	        	 				col.display= '<label>'
	     
	        					empty = false;
	        	 				cleanRow.push( col);
	        	 			}
	        				else if ( (! col) || col.empty || (! col.visible) )
	        				{
	        					let elem = {};
	        					Object.assign( elem, EMPTY_FIELD);
	        					cleanRow.push( elem);
	        				}	
	        				else
	        				{
	        					empty = false;
	        					cleanRow.push( col);
	        				}
	        			}
	        			let span = 0;
	        			for ( let c in cleanRow )
		        		{
		        		    let col = cleanRow[c];
		        		    span += col.span;
		        		}
	        			while ( span < tab.cells )
	        			{
	        				let elem = {};
        					Object.assign( elem, EMPTY_FIELD);
        					cleanRow.push( elem);
	        				span++;
	        			}
	        			if ( !firstRow || !empty )
	        			{
	        				if ( ! this.isEmpty(cleanRow) )
	        				{
	        					cleanRows.push(cleanRow);
	        				}
	        				lastRowEmpty = empty;
	        			}

	        		}
	        	}
	        	//alert(tab.folder+":"+cleanRows.length)
	        	this.folderDefinition[f].rows = cleanRows;
	        	if ( (cleanRows.length === 0))
	        	{
	        		
	        	    this.addEmptyRow(tab)
	        	}

	        	
	        }
	        this.selectedFolder = this.folderDefinition[this.selectedTab];
        },
        createStructure( folder)
        {
        	if ( !folder.folderStructure)
        	{
        		let structure = { id: folder.id, rows: [] };
        		let row = [];
        		
        		for ( let c in folder.columns )
        		{
        			let col = folder.columns[c];
        			row.push( {name: col, span:1, empty: false, visble: true});
        			if ( row.length >= folder.cells )
        			{
        				structure.rows.push( row);
        				row = [];
        			} 
        		}
        		if ( row.length > 0 )
    			{
    				structure.rows.push( row);
    			} 
        		return structure;
        	}
        	return folder.folderStructure;
        },
        calcData()
	    {
	    	try{
	    	let that = this;
	        that.folderDefinition = new Array();
	        
	        for (let f in that.metadata.folder)
	    	{
	    	 	let fld = that.metadata.folder[f];
	         	let structure = fld.folderStructure;
	         	if ( !structure)
	         	{
	         		fld.folderStructure = this.createStructure( fld);
	         		structure = fld.folderStructure;
	         	}
	         	if ( structure)
	         	{
		         	structure.folder = fld.name;
		         	structure.display = fld.display;
		         	structure.cells = fld.cells;
		         	for ( let r in structure.rows)
		    	 	{
		    	 		let row = structure.rows[r];
		    	 		if ( row )
		    	 		{
		    	 			//alert( JSON.stringify(row));
			    	 		for ( let c in row)
			    	 		{
			    	 			let col = row[c];
			    	 			if ( col )
			    	 			{
			    	 				if ( col.name === SEPARATOR_FIELD.name )
			        	 			{
			        	 				col.visible = true;
			        	 				col.display= '<separator>'
			        	 				col.data = {};
			        	 				Object.assign( col.data, EMPTY_COL);
			        	 				
			        	 			}
			        	 			else if ( col.name === LABEL_FIELD.name )
			        	 			{
			        	 				col.visible = true;
			        	 				col.display= '<label>'
			        	 				col.data = {};
			        	 				Object.assign( col.data, EMPTY_COL);
			        	 				
			        	 			}
				    	 			else if ( col.empty )
				    	 			{
				    	 				Object.assign(row[c], EMPTY_FIELD);
				    	 			}
				    	 			else
				    	 			{
				        	 			let column = that.findColumn(col.name);
				        	 			col.data = column;
				        	 			col.visible = column.visible;
				        	 			//alert(JSON.stringify(column))
				        	 		}
				        	 	}
			    	 		}
			    	 	}
		    	 	}
		    	 	
		    	 	that.folderDefinition[f] = structure;
		    	 }
	    	}
	    	//alert(JSON.stringify(that.folderDefinition))
            if ( that.folderDefinition && that.folderDefinition.length > 0 )
            {
            	that.clearStructure();
            	
            	that.getAllColumns();
            	return;
            }
	        
			let folderDef = new Array();
        	
        	let tab = null;
        	for ( tab in that.metadata.folder)
        	{
        		
        		let folder = that.metadata.folder[tab];
        		//alert( folder.name+":"+folder.cells);
        		let cols = folder.columns;
        		let x = 0;
        		let y = 0;
        		let col = null;
        		let rowArr = new Array();
        		let columnArr = new Array();
        		for ( col in cols )
        		{
        			let column = that.findColumn(cols[col]);
        			if ( x >= folder.cells  )
        			{
        				x = 0;
        				y++;
        				columnArr = new Array();
        			}
        			if ( this.isLink(column) || (y > 0 && x > 0 && column.newline === true) )
        			{
        				x = 0;
        				y++;
        				columnArr = new Array();
        			}
        			if ( ! column || (! column.display && !this.isLink(column))  )
        			{
        			    columnArr[x] = {};
        				Object.assign(columnArr[x], EMPTY_FIELD);
        			}
        			else 
        			{
        				if ( this.isLink(column) )
        				{
        					columnArr[x] = { name: column.name, empty: false,  visible: column.visible, span: folder.cells, data: column };
        				}
        				else
        				{
        					columnArr[x] = { name: column.column, empty: false,  visible: column.visible, span: column.span, data: column };
        				}
        			}
        			rowArr[y] = columnArr;
        			//alert(JSON.stringify(rowArr[y]))
        			if ( column.span )
        			{
        				x += column.span;
        			}
        			else
        			{
        				x++;
        			}
        			console.log(column.display +"/"+col+" --> " + x)
        		}
				
        		if ( x >= 0 && x < folder.cells )
    			{
    				columnArr[x] = {};
    				Object.assign( columnArr[x], EMPTY_FIELD);
    				rowArr[y] = columnArr;
    			}
        		let idx = 0;
    			columnArr = new Array();
    			for ( idx = 0; idx < folder.cells; idx++)
    			{
    				columnArr[idx] = {};
    				Object.assign( columnArr[idx], EMPTY_FIELD);
    			}
    			rowArr[y+1] = columnArr;
        		folderDef[tab] = {id: folder.id, folder: folder.name, display: folder.display, cells: folder.cells, rows: rowArr}
        		 
        	}
        	//console.log(JSON.stringify(folderDef))
        	that.folderDefinition = folderDef;
        	that.clearStructure();
        	that.getAllColumns();
        	//alert( JSON.stringify(folderDef));
        	} catch (e) {
        		alert("calc() " + e);
        	}
        },
        isEmpty(columnArr)
        {
        	for ( let c in columnArr )
        	{
        		if ( ! columnArr[c].empty === true || columnArr[c].name === SEPARATOR_FIELD.name || columnArr[c].name === LABEL_FIELD.name)
        		{
        			return false;
        		}
        	}
        	return true;
        },
        removeColumn( tab, column)
        {
        	let rows = this.folderDefinition.find(f=>f.folder===tab.folder).rows;
        	
        	for ( let r in rows )
        	{
        		let row = rows[r];
        		for ( let c in row )
        		{
        		    let col = row[c];
        			//alert( JSON.stringify(col)+ "/"+ column.column);
        			if ( col === column )
        			{
        				row.splice(c, 1)
        			}
        		}
        	}
        },
        addEmptyColumn( event, tab, column)
        {
        	let rows = this.folderDefinition.find(f=>f.folder===tab.folder).rows;
        	
        	for ( let r in rows )
        	{
        		let row = rows[r];
        		for ( let c in row )
        		{
        		    let col = row[c];
        			//alert( JSON.stringify(col)+ "/"+ column.column);
        			if ( col === column )
        			{
        				let elem = {};
        				Object.assign( elem, EMPTY_FIELD );
        				row.splice(c, 0, elem)
        			}
        		}
        	}
        	this.changes++;
        },
        addEmptyRowBefore( event, tab, column)
        {
        	let rows = this.folderDefinition.find(f=>f.folder===tab.folder).rows;
        	
        	for ( let r in rows )
        	{
        		let row = rows[r];
        		
        		for ( let c in row )
        		{
        		    let col = row[c];
        			//alert( JSON.stringify(col)+ "/"+ column.column);
        			if ( col === column )
        			{
        				let newRow = new Array();
			        	while ( newRow.length < tab.cells )
						{
							let elem = {};
							Object.assign( elem, EMPTY_FIELD);
							newRow.push( elem);
						}
        				rows.splice( r, 0, newRow);
        				this.changes++;
        				return;
        			}
        		}
        	}
        	
        },
        addEmptyRow(tab)
        {
        	try {
        	
        	let rows = this.folderDefinition.find(f=>f.folder===tab.folder).rows;
        	//alert("addEmptyRow("+tab.folder+"): current:"+(rows?rows.length:rows))

        	let lastRow = new Array();
        	while ( lastRow.length < tab.cells )
			{
				let elem = {};
				Object.assign( elem, EMPTY_FIELD);
				lastRow.push( elem);
			}
			if ( rows )
			{
				this.folderDefinition.find(f=>f.folder===tab.folder).rows.push(lastRow);
			}
			else
			{
				this.folderDefinition.find(f=>f.folder===tab.folder).rows = [lastRow];
				//this.folderDefinition.find(f=>f.folder===tab.folder).rows.push(lastRow);
			}
			
			//this.$forceUpdate();
			//alert( JSON.stringify( this.folderDefinition.find(f=>f.folder===tab.folder)));
			}
			catch (e) {alert(e);}
			this.changes++;
        },
        addCellToTable( tab)
        {
        	let rows = this.folderDefinition.find(f=>f.folder===tab.folder).rows;
        	
        	tab.cells++;
        	for ( let r in rows )
        	{
        		let row = rows[r];
        		if ( row )
        		{
        			let span = 0;
        			for ( let c in row )
	        		{
	        		    let col = row[c];
	        		    span += col.span;
	        		}
        			while ( span < tab.cells )
        			{
        				let elem = {};
        				Object.assign( elem, EMPTY_FIELD );
        				row.push( elem);
        				span++;
        			}
        		}
        		
        	}
        	this.changes++;
        },
		removeCellFromTable( tab)
        {
        	let rows = this.folderDefinition.find(f=>f.folder===tab.folder).rows;
        	
        	tab.cells--;
        	for ( let r in rows )
        	{
        		let row = rows[r];
        		if ( row )
        		{
        			let newRow = new Array();
        			let span = 0;
        			for ( let c in row )
	        		{
	        		    let col = row[c];
	        		    if ( col )
	        		    {
		        		    if ( span >= tab.cells )
	        				{
	        					if ( ! col.empty )
	        					{
	        						newRow.push(col);
	        					}
	        				}
	        				else
	        				{
	        					newRow.push(col);
	        					span += col.span;
	        				}
        				}
	        		}
	        		//alert( JSON.stringify(newRow));
	        		rows[r] = newRow;
        		}
        		
        	}
        	this.changes++;
        	this.$forceUpdate();
        },
        reCalcData()
	    {
	        let that = this;
			let folderDef = new Array();
        	
        	let tab = null;
        	for ( tab in that.folderDefinition)
        	{
        		
        		let folder = that.folderDefinition[tab];
        		let rows = folder.rows;
        		
        		let x = 0;
        		let y = 0;
        		let col = null;
        		let rowArr = new Array();
        		let columnArr = new Array();
        		for ( let rowIdx in rows )
        		{
        		 	let row = rows[rowIdx];
        		 	
	        		for ( col in row )
	        		{
	        			
	        			let column = row[col].data;
						
	        		    if ( x >= folder.cells  )
	        			{
	        				x = 0;
	        				if (!this.isEmpty(columnArr) )
	        				{
	        					y++;
	        				}
	        				columnArr = new Array();
	        			}
	        			columnArr[x] = { name: column.column, empty: row[col].empty, visible: column.visible, span: column.span, data: column };
						//alert(this.isEmpty(columnArr)+ "/"+JSON.stringify(columnArr))
	        			if (!this.isEmpty(columnArr) )
	        			{
	        				
	        				rowArr[y] = columnArr;
	        			}
	        			//alert(JSON.stringify(rowArr[y]))
	        			if ( column.span )
	        			{
	        				x += column.span;
	        			}
	        			else
	        			{
	        				x++;
	        			}
	        			
	        		}
	        		if ( x >= 0 && x < folder.cells )
        			{
        			 	Object.assign( columnArr[x], EMPTY_FIELD );
        				
        				if (!this.isEmpty(columnArr) )
        				{
        					rowArr[y] = columnArr;
        				}
        			}
	        		x = 0;
					y++;
					columnArr = new Array();
	        	}

				let idx = 0;
    			columnArr = new Array();
    			for ( idx = 0; idx < folder.cells; idx++)
    			{
    				Object.assign(columnArr[idx] , EMPTY_FIELD);
    			}
    			
    			rowArr[y+1] = columnArr;
        		folderDef[tab] = {id: folder.id, folder: folder.folder, display: folder.display, cells: folder.cells, rows: rowArr}
        		 
        	}
        	//console.log(JSON.stringify(folderDef))
        	that.folderDefinition = folderDef;
        },
        getParentMeta() { return this.parentMeta; },
        getCreatables( links, data)
        {
        	let newArray = [];
        	for ( var i in links )
        	{
        		if ( ! links[i].createIf)
        		{
        			newArray.push( links[i]);
        		}
        		else 
        		{
        			if ( data[ links[i].createIf ] )
        			{
        				newArray.push( links[i]);
        			}
        		}
        	}
        	for ( var ii in this.metadata.columns )
        	{
        		let c =  this.metadata.columns[ii];
        		//console.log(c.column + " -> "+this.isSelect(c))
        	}
        	return newArray;
        },
        getDynLink(column) {
       		return false;
        },
        getDynMLink(column) {
       		return false;
        },
        getDynDates(column) {
       		return false;
        },
        useCopyFrom( column)
        {
        	let value = this.data[column];
        	//alert( column + "; " + value);
        	for ( var i in this.copyToColumn[column])
        	{
        		let copyTo = this.copyToColumn[column][i];
        		//alert( "--> " + copyTo + "; " + this.data[ copyTo ]);
        		//alert( "--> " + copyTo + "; " + this.findColumn( copyTo ).defaultValue);
        		if ( (! this.data[ copyTo ]) || (this.data[ copyTo ] == this.findColumn( copyTo ).defaultValue))
        		{
        			this.data[ copyTo ] = value;
        		}
        	}
        	
        },
        onSelect(column) 
        {
        	for ( let disableMe in this.metadata.onSelect )
            {
            	if ( column == this.metadata.onSelect[ disableMe ].second )
	        	{
	        		this.findColumn( this.metadata.onSelect[ disableMe ].first).editable = !! (this.data[column]);
	        		if ( ! this.data[column] && this.data[this.metadata.onSelect[ disableMe ].first] )
	        		{
	        			this.data[this.metadata.onSelect[ disableMe ].first]=0;
	        		}
	        	}
            }
        	
        },
        error( txt, err)
	    {
	      if ( err && err.response && err.response.data )
	      {
	        let info = err.response.data;
	        try
	        {
		        let scanner = new RegExp("[\\[]([a-zA-Z_0-9]*)[\\]]([.]*)");
		        let myArray = scanner.exec(info);
		       
		        if ( myArray[1] )
		        {
		            info = info.substring(myArray[1].length+2);
		        	this.mark[ myArray[1]] = true;
		       		//alert( myArray[1] + "-->"+ JSON.stringify(this.findColumn( myArray[1])));
		      		this.printMsg( err.response.status, txt+ ": " + info);	
		      	}
		      	else
		      	{
		      		this.printMsg( err.response.status, txt+ ": " +err.response.data);	
		      	}
		     }
		     catch(e) {
		     	this.printMsg( err.response.status, txt+ ": " +err.response.data);	
		     }
	      }
	      else 
	      {	
	      	this.errorTypePU=txt;
	      	if ( ! err )
	      	{
	        	this.errorPU = {'message': ""};
	      	}
	      	else
	      	{
	      		this.errorPU = err;
	      	}
	      }
	    },
        getDisplay(column) 
        { 
            if (this.visible(column.data)) 
            {
            	return column.data.display; 
            }
            
            return "";
        },
        visible(column) { return column && column.visible;},
        getModalStyle() { return "'z-index: " + (2+this.zIndex)+";'"; },
        incZIndex() { return parseInt(this.zIndex)+1; },
        clearMsg()
        {
        	this.message = "";
        	this.mark=[];
        },
        printMsg(status, msg)
        {
        	if ( parseInt(status) != 200 )
        	{
        		this.msgClass = "msgError";
        		this.$toast.error( msg, 'Error', { position: "topRight" });
        	}
        	else
        	{
        		this.msgClass = "msgDefault";
        	}
        	timers.push( setTimeout(this.hideSuccess, 4000));
        	this.message = msg;
        },
        findColumn(column) { 
           //console.log( column );
           var colObject=this.metadata.columns.find(p => p.column === column); 
           //console.log( colObject );
           if ( colObject ) { 
       
              return colObject; 
           } 
           else { 
              colObject=this.metadata.links.find(p => p.name === column); 
              if ( colObject ) { 
                colObject.visible = true;
              	return colObject; 
              } 
              else {
                return {};
              }
           }
        },
        addPath()
        {
        	var p = JSON.parse(JSON.stringify(this.path));
        	p.push({name: this.data.visualDisplay, entity: this.metadata.name, position: p.length});
        	return p;
        },
        newDetail(data, link)
        {
        	//console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/new/"+link );
        	if ( !data.id || ! data.id > 0 )
        	{
        		this.error("Please, save the data first", "");
        		return;
        	}
        	let myLink = this.metadata.links.find( l=>l.entity == link);
        	let fromEntity = this.metadata.name;
        	let parentID = data.id;
        	let parentMeta = this.metadata;
        	let parentRecord = data;
        	if ( ! myLink && this.parentMeta  )
        	{
        		myLink = this.parentMeta.links.find( l=>l.entity == link);
        		fromEntity = this.parentMeta.name;
        		parentID = this.parentId;
        		parentMeta = this.parentMeta;
        		parentRecord = this.parentRecord;
        	}

			HTTP.post( fwAPI+"/createNewDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+link, parentRecord)
                    .then( response => {
                    this.selectedDetail = response.data.data;
                    this.selectedDetail.tags= new Array();
                    this.metadataDetail =  response.data.metadata;
                    
                    this.detailEntityMap = response.data.entity2DataMap;
                    //console.log( JSON.stringify( this.detailEntityMap ));
                    this.currentParentId = parentID;
					this.currentParentMeta = parentMeta;
					this.currentDataRecord = parentRecord;
					
					this.detailCounter++;
					//console.log("detailCounter> " + this.detailCounter)
					this.showEditorDetail = true;
                    //
                }).catch(e => {
                     this.error("can't create new "+link, e);
                });
        	
        },
		run(metadata, fds)
        {
            let fromEntity = metadata.name;
            let that = this;
			if ( fds.needsField)
			{
				this.$iosPrompt({
				      text: 'Enter '+fds.needsField,
					  placeholder: ''
				}).then( r => {
				if ( r )
				{
					let runObject = { json: JSON.stringify(this.moduleData), neededValue: r}
									           
					HTTP.post( fwAPI+"/runFunction/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+this.moduleData.id+"/"+fds.functionName+"/"+0, runObject, {timeout: 30000000})
					//HTTP.post( fwAPI+"/runFunction/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+this.moduleData.id+"/"+fds.functionName+"/0", this.moduleData)
		                    .then( response => {
		                    that.reset();
		                    that.$toast.success("function " + fds.name + " returned: "+  response.data, 'Ok', { position: "topRight" });
		                    //
		                }).catch(e => {
		                     //alert(2);
		                     this.error("error in function " + fds.name, e);
		                });
				}})
			}
            else
			{
				let runObject = { json: JSON.stringify(this.moduleData), neededValue: ''}
				           
				HTTP.post( fwAPI+"/runFunction/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+this.moduleData.id+"/"+fds.functionName+"/"+0, runObject, {timeout: 30000000})
				//HTTP.post( fwAPI+"/runFunction/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+this.moduleData.id+"/"+fds.functionName+"/0", this.moduleData)
	                    .then( response => {
	                    that.reset();
	                    that.$toast.success("function " + fds.name + " returned: "+  response.data, 'Ok', { position: "topRight" });
	                    //
	                }).catch(e => {
	                     //alert(2);
	                     this.error("error in function " + fds.name, e);
	                });
			}
        },
        openLink(id, link, allIds)
        {
        	//console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/"+id+"/"+link );
        	this.allDetailIDs = [];
        	if ( allIds )
        	{
				for ( var x in allIds )
				{
					this.allDetailIDs.push({row: x, dataId: allIds[x], rId: allIds[x], visible: true });
				}
			}
			else
			{
				this.allDetailIDs.push({row: 0, dataId:id, rId: id, visible: true });
			}
			this.currentParentId = this.visualMapping[ this.visualIndex ].dataId;
			this.currentParentMeta = this.metadata;
			HTTP.post( fwAPI+"/getLinkedDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/"+id+"/"+link)
                    .then( response => {
                    this.selectedDetail = response.data.data;
                    this.metadataDetail =  response.data.metadata;
                    this.detailEntityMap = response.data.entity2DataMap;
                    //console.log( "OPEN-"+link+ " " +JSON.stringify( this.detailEntityMap));
					this.showEditorDetail = true;
					//alert( JSON.stringify(this.selectedDetail));
                    //
                }).catch(e => {
                        this.error("can't open "+this.metadata.name, e);
                });
        	
        },
        closeUntilPosition(position)
        {
        	//alert( position +"/" + this.path.length);
        	if ( !this.path || position < this.path.length )
        	{
        		this.close();
        	}
        	this.$emit("closeUntilPosition", position)
        },
        closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
        getImgData(val) { return 'data:image/png;base64,' + val; },
        hideSuccess() { this.success = false; this.clearMsg(); clearInterval( timers.pop()); },
        getData(id)
        {
        	//console.log( fwAPI+"/"+this.metadata.name+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+id);
            HTTP.get( fwAPI+"/entityById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/"+id)
                    .then( response => 
                    {
                    	if ( ! response.data.metadata )
                    	{
                         	this.data = response.data;
                         	this.getUser(this.data.lUserId); 
                        }
                        else
                        {
                        	this.data = response.data.data;
                        	this.metadata = response.data.metadata;
                         	this.getUser(this.data.lUserId); 
                         	this.calcData()
                        }
                         
                    }).catch(e => {
                        this.error("can't get "+this.metadata.name+" data ID="+id, e);
                    });
        },
        left() {

        },
        right() {

        },
        last() {

        },
        first() {

        },
        folderColumnsIndex(tabColumns) {
            var intArr = [];
            for ( var i=0; i < tabColumns.length; i++ )
            {
                intArr.push(i);
            }
            return intArr;
        },
        // style='display: inline-flex; width: 25%;'
        toolTip( tab, column)
        {
        	 if ( column.data.tooltip )
        	 {
        	 	return column.data.tooltip;
        	 }
        	return {};
        },
        getLabelClass( tab, column) 
        {
        	 if ( column.data.mandatory )
        	 {
        	 	return "elementLabel required";
        	 }
        	return "elementLabel";
        },
       
        getStyleX( tab, col) 
        { 
            let column = col.data;
            //let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: column;
            
            let style = "";
            if ( col.empty && ! (col.name === SEPARATOR_FIELD.name))
            {
            	style += "margin: 1pt;";
            }
            if ( col.empty && ! (col.name === LABEL_FIELD.name))
            {
            	style += "margin: 1pt;";
            }
            let folder = this.metadata.folder.find( f => f.name === tab.folder);
            if ( folder && folder.paddingBottom )
            {
            	style += "padding-bottom: "+folder.paddingBottom+";";
            }
            if ( folder && folder.paddingTop )
            {
            	style += "padding-top: "+folder.paddingTop+";";
            }
            if ( folder && folder.paddingBottom )
            {
            	style += "padding-left: "+folder.paddingLeft+";";
            }
            if ( folder && folder.paddingBottom )
            {
            	style += "padding-right: "+folder.paddingRight+";";
            }
            //console.log(tab.folder+"/"+col.data.column+"/"+style)
            if (this.visible(column)) 
            {
				
                return style+"width: 100%;";
            } 
            else 
            {
                return style+"display: none;";
            } 
        },  
        getLabelStyleX(tab, column) 
        { 
            //let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: columnName;
            let folder = this.metadata.folder.find( f => f.name === tab.folder);
            let width = "80pt;";
            if ( folder && folder.labelWidth )
	        {
            	width = folder.labelWidth;
            }
            
            let fontStyle = (column.data.tooltip)? "text-decoration: underline;": "";
            let fontSize = "font-size: 12pt;";
            if ( folder && folder.labelFontSize )
	        {
            	fontSize = "font-size: "+folder.labelFontSize+";";
            }	
            let style = fontStyle+fontSize+"width: " + width+" !important; cursor: pointer;"; //border: 1pt solid green;";
            console.log( style);
            return style;
            /*
            if (this.visible(column)) {
                if ( this.visible(columnNext) ) 
                { 
                    return fontStyle+"width: " + (width)+unit;
                }
                else 
                {
                    return fontStyle+"width: " + (width/tab.cells)+unit;
                }
            } else {
                return fontStyle+"width: " + (width)+unit;
            } 
            */
        },  
        getFieldStyleX(tab, column) 
        { 
            //let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: columnName;
            let folder = this.metadata.folder.find( f => f.name === tab.folder);
            let lWidth = "80pt;";
            if ( folder && folder.labelWidth )
	        {
            	lWidth = folder.labelWidth;
            }
            let width = "calc(100% - "+lWidth+")"
           
            let fontStyle = (column.data && column.data.tooltip)? "text-decoration: underline;": "";
            
            return fontStyle+"width: " + (width)+" !important; cursor: pointer;"; // +";border: 1pt solid green;";
            /*
            if (this.visible(column.column)) {
                if ( this.visible(columnNext) ) 
                { 
                    return fontStyle+"width: " + (width)+unit;
                }
                else 
                {
                    return fontStyle+"width: " + (width/tab.cells)+unit;
                }
            } else {
                return fontStyle+"width: " + (width)+unit;
            } 
            */
        },  
        
        passUpdate(entity, record) {
            this.updateCount++;
        	this.$emit( "update", entity, record);
        },
        passInsert(entity, record) {
            this.updateCount++;
        	this.$emit( "insert", entity, record);
        },
        update() 
        { 
        	if ( this.metadata && this.metadata.id )
        	{
        	 //alert( fwAPI+"/modify/definition/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name);
        	 //alert("save"); 
        	 let that = this;
        	 delete this.metadata.links;
        	 delete this.metadata.menu;
        	 delete this.metadata.persistent;
        	 delete this.metadata.tableVirtual;
        	 delete this.metadata.functions;
        	 delete this.metadata.selects;
        	 delete this.metadata.onSelect;
        	 delete this.metadata.cacheName;
        	 for ( let c in this.metadata.columns )
        	 {
        	    this.metadata.columns[c] = {id: this.metadata.columns[c].id, 
        	                                type: this.metadata.columns[c].type, 
        	                                column: this.metadata.columns[c].column, 
        	                                newline: this.metadata.columns[c].newline, 
        	                                span: this.metadata.columns[c].span};
        	 }
        	 
        	 for (let f in this.metadata.folder)
        	 {
        	 	let fld = this.metadata.folder[f];
        	 	fld.folderStructure = this.folderDefinition[f];
        	 	if ( fld.folderStructure)
        	 	{
	        	 	delete fld.folderStructure.folder;
	        	 	delete fld.folderStructure.display;
	        	 	delete fld.folderStructure.cells;
	        	 	for ( let r in fld.folderStructure.rows)
	        	 	{
	        	 		let row = fld.folderStructure.rows[r];
	        	 		for ( let c in row)
	        	 		{
	        	 			let col = row[c];
	        	 			
	        	 			delete col.data;
	    
	        	 		}
	        	 		
	        	 	}
	        	 }
	        	 else
	        	 {
	        	 	this.metadata.folder.splice(f, 1);
	        	 }
        	 	//alert(JSON.stringify(fld.folderStructure))
        	 }
			 //if ( this != null ){
        	 //return;
        	 //}
        	 let saver = new Promise(function (resolve, reject) {
	             HTTP.put( fwAPI+"/modify/definition/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+that.metadata.name, that.metadata)
	                    .then( response => 
	                    {
	                        
	                         that.metadata = response.data;
	                   		 that.metadata.editable = true;
	                         that.success = true;
	                         that.folderDefinition = new Array();
	                         for (let f in that.metadata.folder)
				        	 {
				        	   
				        	 	let fld = that.metadata.folder[f];
	                         	let structure = fld.folderStructure;
	                         	if ( structure )
	                         	{
		                         	structure.folder = fld.name;
		                         	structure.display = fld.display;
		                         	structure.cells = fld.cells;
		                         	for ( let r in structure.rows)
					        	 	{
					        	 		let row = structure.rows[r];
					        	 		if ( row )
					        	 		{
						        	 		for ( let c in row)
						        	 		{
						        	 			let col = row[c];
						        	 			if ( ! col )
						        	 			{
						        	 				Object.assign( row[c], EMPTY_FIELD);
						        	 			}
						        	 			else if ( col.name === SEPARATOR_FIELD.name )
						        	 			{
						        	 				col.visible = true;
						        	 				col.display= '<separator>'
						        	 				col.data = EMPTY_COL;
						        	 				//alert(JSON.stringify(col))
						        	 			}
						        	 			else if ( col.name === LABEL_FIELD.name )
						        	 			{
						        	 				col.visible = true;
						        	 				col.display= '<label>'
						        	 				col.data = EMPTY_COL;
						        	 				//alert(JSON.stringify(col))
						        	 			}
						        	 			else if ( col.empty )
						        	 			{
						        	 				col.visible = true;
						        	 				col.display= ''
						        	 				col.data = EMPTY_COL;
						        	 				//alert(JSON.stringify(col))
						        	 			}
						        	 			else
						        	 			{
							        	 			let column = that.findColumn(col.name);
							        	 			col.data = column;
							        	 			col.visible = column.visible;
							        	 			//alert(JSON.stringify(column))
							        	 		}
						        	 		}
						        	 	}
				        	 		}
				        	 		that.folderDefinition[f] = fld.folderStructure;
				        	 		that.clearStructure();
				        	 	}
				        	 	
				        	 	//alert(JSON.stringify(that.folderDefinition[f]))
	                         }
	                         that.printMsg( 200, "Saved");
						     resolve(true);
	                         
	                    }).catch(e => {
	                        that.error("update failed", e)
	                        that.$toast.error("update failed: " + e.response.data, 'Error', { position: "topRight" });
	                        reject();
	                    });
     		   });
     		   saver.then( p => { 
  				that.changes=0; // reset changes
     		   	return true;
     		   });
  
           } 
            
        },
        getUser(id)
        {
            this.lastUser = {};
            if ( id === 0 )
            {
            	this.lastUser.login = "system";
            }
            else
            {
            	HTTP.get( fwAPI+"/findUser/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+id)
                    .then( response => 
                    {
                         this.lastUser = response.data;
                        
                    }).catch(e => {
                        this.error("insert failed", e)
                        this.error("getUser("+id+") failed", e)
                    });
            }
        },
        selectFirstTab(tab, tabIdx)
        {
        	let firstTab = (tabIdx==0);
        	
            console.log("selectTab() --> " + firstTab+"/"+tabIdx+ " :"+  JSON.stringify(tab));
        	return firstTab;
        },
        tabChange(index)
        {
        	this.selectedTab = index;
        	this.selectedFolder = this.folderDefinition[index];
        	//alert("tabChange() --> " +index);
        },
        
        get(col)
        {
        	//console.log( "**** " + col)
        	return col;
        },
        readDataRecord()
        {
        	alert("REALLY");
        	
        	//console.log( JSON.stringify(this.dataRecord));
        	if ( this.dataRecord.id > 0 )
        	{
        		this.getData( this.dataRecord.id);
        	}
            this.data = this.copy(this.dataRecord);
            this.recordId = this.dataRecord.id;
            for ( var disableMe in this.metadata.onSelect )
            {
            	this.findColumn( this.metadata.onSelect[ disableMe ].first).editable = false;
            }
            
            for ( var col in this.metadata.columns) 
            {
            	let gCol = this.metadata.columns[col];
            	if ( gCol.enableIf )
            	{
            		let column = gCol.enableIf;
	           		let positive = true;
	           		if ( column.startsWith("!"))
	           		{
	           			positive = false;
	           			column = column.substring(1);
	           		}
	         	    let enableIfCol = this.findColumn( column);
	            	if ( this.data[ column ] )
	            	{
	            		gCol.editable = positive;
	            	}
	            	else
	            	{
	            		gCol.editable = !positive;
	            	}
            	}
            }
            
            if ( this.data.lUserId )
            {
            	this.getUser(this.data.lUserId); 
            }
            this.index = this.selectedIndex; 
            this.selectedTab = 0;
            this.numberOfRows = 0;
            //console.log( JSON.stringify(this.mapping));
            this.visualMapping = [];
            this.visualIndex = 0;
            for ( var x in this.mapping )
            {
            	//console.log( JSON.stringify(this.mapping[x]));
            	if ( this.mapping[x].visible )
            	{
            		this.numberOfRows++;
            		
            		if ( this.data.id == this.mapping[x].dataId )
            		{
            			this.visualIndex = this.visualMapping.length;
            		}
            		this.visualMapping.push( this.mapping[x] );
            	}
            }
        }
  },
   updated() {
     
  },
  computed: {
	msgDisplayClass: function () { return this.msgClass; }
  },
  
  created() {		
  	this.getMetaMeta();
  },
   watch:
   {
   		
   	    metadata: function() {
   	        
   	        this.getMetaMeta();
   	        
   	    },
        dataRecord: function()  { 
        	this.readDataRecord();
        }
   }
}
</script>
<style  scoped>

.draggable {
	background-color: #fda;
}
.ondrag {
     border-top: .5pt inset grey !important;
     border-left: .5pt inset grey !important;
     border-right: .5pt inset grey !important;
     border-bottom: .5pt inset grey !important;
	 padding-bottom: 9pt;
}
.editorElement {
     border-top: 0.5pt solid transparent;
     border-left: 0.5pt solid transparent;
     display:inline-block;
     white-space: nowrap;
 }
 .transition {
 	transition: all 1s;
     -webkit-transition: all 1s;
 }
.elementLabel {
    display: inline-flex;
    white-space: nowrap;
    float: left;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    display: inline-grid;
    white-space: nowrap;
 }
.lUpdateInfo {
    font-size: 11pt;
    color: #888;
}
.lUpdateInfoSuccess {
    vertical-align: middle;
}
.mainContent {
    height: 100%;
    transition: all 0.4s ease;
}
.appHeadLine { 
    width: 100%; 
    border-bottom: 1pt solid #aaa; 
    text-align: right;
    margin-bottom: 2pt; 
    font-weight: bold;
    padding: 10pt 10pt 1pt;}
    
.appCounter {
	display: inline-grid;
	font-size: 11pt;
	color: #888;
}    
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #f88;
    padding: 0pt 0pt 0pt;}
    
.modal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}
.shadow {
    border: 3px inset #f88;
    box-shadow: 5px 5px 8px 0 #f88;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #ff8888;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-nav {
    font-size: 9pt;
    vertical-align: middle;
    v-align: middle;
}
.btn-save {
	background-color: #080;
    color: #fff;
}
.btn-close {
	background-color: #efefef;
}
.btn-reset {
	background-color: #efefef;
}
.btn-editor {
 	background-color: #fda;
 	font-weight: bold;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}
.msgDefault {
	font-size: 9pt;
}
.selector {
	padding-left: 10pt;
	margin-right: 5pt;
	font-size: 11pt;
	padding-bottom: 0pt;
    padding-top: 0pt;
    margin-bottom: 0pt;
    margin-top: 0pt;
    height: 16pt;
	border: .5pt solid transparent;
}
.selector:hover 
{
	background-color: #ddd;
	border: .5pt solid #888;
}
.editorElement:hover 
{
	background-color: #fdd;
}
.msgError {
	font-size: 9pt;
	font-weight: bold;
	color: #bb0000;
}
hr.separator {
  margin-left: 30pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding: 0pt;
  border-top: 1pt solid #ddd;
  border-radius: 5px;
}
</style>