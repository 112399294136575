<template>
 <div>
    <vue-modal-2 :noFooter=true :noHeader=true ref="showProgress" name="showProgress" style='z-index: 999;'>
       <div :style="getAppStyle()+'padding: 5px;border-radius: 0pt;'">
       <div :style="getBGStyle2()+'border-radius: 4pt; box-shadow: 2px 2px 6px rgba(0, 0, 220, .6);'">
	   <div v-if="chartData && chartData.length" class='puInfo' :style="'z-index: 1000; padding-left: 10pt; padding-top: .5em; padding-bottom: .4em;'">
	    <div style='float: left'>{{generalInfo}}</div>
	    <div style='float: right'>
	    <button title="Cancel process" :disabled="cancelled"  class='button' :style="'background: transparent; color: '+getFGColor('#f00','#f88')+'; border: none; width: 22pt; margin-right: 3pt; height: 18pt;'" 
	    @click="cancel"><mdicon size="20" name="close-circle-outline"/></button>
	    </div>
	   </div>
	   <div v-else class='puInfo' :style="'z-index: 1000; padding-left: 10pt; padding-top: .5em; padding-bottom: .4em;'">
	    <div>{{generalInfo}}</div>
	   </div>
	   <div v-show="chartData && chartData.length" class='white body' :style="'display: block; margin-top: 8pt; color: #fff; height: 150px; width: 100%;'" >
      
       	<div style='color: #000;' id='perfChart' />
        <div style='height: 5px;'>&nbsp;</div>
        
         <div style='height: 5px;'>&nbsp;</div>
	   </div>
	   <div style="padding-left: 10pt; font-size: 8pt;" class='puInfo'>{{ infoStr }}</div>
       <div style='height: 8px;'>&nbsp;</div>
       <div style="display: flex; position: relative; left: 0; padding-left: 10pt;">
	       <progress-bar v-show="valueInt >= 0"
	       :options=PBOptions
	       :value="valueInt"
	       />
       </div>
	   </div>
	   </div>
   </vue-modal-2>
 </div>
</template>
<script>
import {HTTP, getWSURL, APIURL, APIURLB, APIURLA, port_LB, myLocale, serverConst, showError, getServerInfo, xApi} from '@/variables.js';
import { getAppStyle, initAppMode, getBGStyle2, getFGColor } from '@/AppStyle.js';
import ApexCharts from 'apexcharts'
export default {
  props: {
    generalInfo: { type: String },
    action: { type: String }
  },
  data () {
    return {
        valueInt: 0,
        infoStr: "",
        getBGStyle2,getAppStyle,getFGColor,
        chartData: [],
        chart: null,
        cancelled: false,
        chartOptions: {},
     	PBOptions: {
		  text: {
		
		    fontSize: 11,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#2daa2d',
		    backgroundColor: '#444'
		  },
		  layout: {
		    height: 20,
		    width: 320,
		    verticalTextAlign: 61,
		    horizontalTextAlign: 43,
		    zeroOffset: 8,
		    strokeWidth: 30,
		    progressPadding: 1,
		    type: 'line'
		  }
        }
    }
  },
  components : {
    
  },
  computed: 
  {
    
  },
  created() 
  {
  		if ( this.action != "" )
  		{ 
    		this.start();
    	}
  		this.cancelled = false;
  		initAppMode();
  },
  methods: 
  {
	cancel()
	{
         this.websocket.send( "WSCancel" );
         this.cancelled = true;
	},
    start()
  	{
  		
  		this.valueInt = 1;
  		let myWSURL = getWSURL();
		this.chartData = [];
  		getServerInfo().then( srvInf => {
  			try
  	  		{
  				//alert( JSON.stringify(srvInf) )
		  		//myWSURL = 'wss://'+sessionStorage.server+':'+srvInf.port+'/wsInit/v1';
		  		//myWSURL = 'ws://'+sessionStorage.server+':'+93+'/wsInit/v1';
		  		//alert( myWSURL )
		  	    this.websocket = new WebSocket(myWSURL);
		  		//this.generalInfo += " ("+srvInf.name+")";
		        this.websocket.onopen = this.onOpen;
		        this.websocket.onmessage = this.onMessage;
		        this.websocket.onerror = this.onError;
	  	  	} catch (e)
	  		{
	  			showError( this.$toast, "WEBSOCKET> ", e);
	  		}
  		});
  		
  	},
  	onOpen(evt) { 
  	   //alert( WSLURL + " is open "+JSON.stringify(evt))
       //console.log("APP.WS> " +JSON.stringify(evt));         
    },  
    onError(evt) { 
    	//alert( WSLURL + " error: "+JSON.stringify(evt))
    	this.$emit("action", this.action, 0, HTTP) 
    }, 
    onMessage(evt)
  	{
  		var data = JSON.parse(evt.data);
  		//alert( WSLURL + " --> "+ data.state+":"+evt.data)
        //console.log( JSON.stringify(data));
        if ( data.state == 'START' )
        {
        	
        	//alert( data.fromServer);
        	//let port = parseInt(data.fromServer.slice(-1))+89;
        	//alert( WSLURL + " --> "+port)
        	let api = xApi( data.protocol, serverConst, data.port);
            //console.log( "WS: >> " +  data.fromServer);
        	//alert( api.defaults.baseURL)
			
            const request = JSON.stringify( {
                className: "WSInit",
                tenantId: sessionStorage.tenantId, 
                jsonPayload: JSON.stringify( {
	                tenantId: sessionStorage.tenantId, 
	                accessPointId: sessionStorage.accessPointId, 
	                userId: sessionStorage.userId, 
	                browser: "",
	                ip: "", 
	                token: sessionStorage.userToken})});
             this.websocket.send( request );
             this.puValueInt = 1;
             this.$vm2.close('showProgress');
             this.$vm2.open('showProgress');
             this.$emit("action", this.action, data.myId, api)     
        }
        else if ( data.state == 'END')
        {
           //console.log("----> WS:: END");
           this.websocket.close();
           this.$vm2.close('showProgress');
        }
        else
        {
            this.infoStr = data.info;
            this.valueInt = Math.round(data.percentage*100)/100;
            if ( data.payload && data.payload.time !== null && data.payload.value !== null)
            {
            	this.chartData.push( {x: Math.round(data.payload.time/1000), y: data.payload.value});
            	this.printChart();
            	this.$forceUpdate();
            }
            this.$forceUpdate();
            if ( this.valueInt >= 100 )
            {
            	this.$vm2.close('showProgress');
            } 
        }
  	},
  	distroyChart()
    {
    	if ( this.chart)
		{
    		try
    		{
				this.chart.destroy();
				this.chart = null;
			}
    		catch(e)
    		{
    		//
    		}
		}
    },
  	printChart()
  	{
    	let col = sessionStorage.fgColor;
    	let colors = [];
    	for ( let x in this.chartData)
    	{
    		colors.push( col);
    	}
    	
    	
  		this.chartOptions = {
  				chart: {
  				    type: 'area',
  				    height: 140,
  				    toolbar: {
  			          show: false
  				    }
  				  },
  				  stroke: {
					  show: true,
			    	  curve: 'smooth',
			    	  width: 1
			    	},
  				  yaxis: {
	  			      show: true,
	  			      logarithmic: true,
	  			    labels: { style: {colors: colors}}
  				  },
  				  xaxis: {
	  			      show: false,
	  			      tickAmount: 10,
	  			      logarithmic: false,
	  			      labels: { style: {colors: colors}}
				  },
  				  legend: {  labels: { colors: [col]}},
				  markers: {
					  size: [0],
				      colors: [col]
					},
  				  dataLabels: {
  				    enabled: false,
  				  },
  				  plotOptions: {
  				    bar: {
  				      horizontal: false
  				    }
  				  },
  				  theme: {
					  palette: 'palette0'
					},
  				  series: [{
  				    data: this.chartData
  				  }]
  		   }
  		   //this.distroyChart();
  		   if (!this.chart)
  		   {
  		   	this.chart = new ApexCharts(document.querySelector("#perfChart"), this.chartOptions);
  		   	this.chart.render();
  		   }
  		   else
  		   {
  			   this.chart.updateOptions( this.chartOptions)
  		   }
  	}
  },
  watch: 
  {
       action: function() { this.start(); }
  }
}
</script>
<style lang="scss" scoped>


.tooltipClass:hover {
  background: #CCC;
}

</style>