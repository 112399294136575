 <template>
   <div>
   
    <div v-if="sharesInfo && sharesInfo.spots">
					
					<div style="float: left; padding-top: 3pt;"><b>Current share distribution</b></div>
					<div style="float: right;"><WindowCloseBN @click="sharesInfo=null;"/></div>
					<table width="100%" >
						<tr><td class="smallText2bx">Share</td>
						<td class="smallText2rx">Limit</td>
						<td class="smallText2rx">Spots</td>
						<td class="smallText2rx">Spots%</td>
						<td class="smallText2rx">Budget</td>
						<td class="smallText2rx">Budget%</td>
						</tr>
						<tr>
						<td class="smallText2x">total</td>
						<td class="smallText2"></td>
						<td class="smallText2">{{sharesInfo.spots}}</td>
						<td class="smallText2"></td>
						<td class="smallText2">{{sharesInfo.budget}}</td>
						<td class="smallText2"></td>
						</tr> 
						<tr v-for="(d,di) in sharesInfo.details" :key="'shd_'+di">
							
							<td v-if="d.share.tree" :title="d.share.name" class="smallText2x">{{ d.share.name }}</td>
							<td v-else :title="d.share.name" class="smallText2x" style="color:#aaa;">{{ d.share.name }}</td>
							<td class="smallText2">{{ d.share.limitOriginal }}</td>
							<td class="smallText2">{{ d.spots }}</td>
							<td class="smallText2">{{ Math.round(1000*d.spots/sharesInfo.spots)/10 }}%</td>
							<td class="smallText2">{{ d.budget }}</td>
							<td class="smallText2">{{ Math.round(1000*d.budget/sharesInfo.budget)/10 }}%</td>
						</tr>
					</table>
					<div style='display: table; position: absolute; bottom: 0; width: 100%;'>
						<button :style="getAppStyle()+'float: right;'" title="back to share definition" class='button myButton' @click='sharesInfo=null'><mdicon :width="16" name="backspace" /></button>
					
					</div>
				</div>
				<div v-else>
   
    <div style="padding-bottom: 12pt;">
		&nbsp;
	    <div style="display: inline-block; width: 25%; float: right; "> 
			<vue-slider :marks=false min=0 :max="25" v-model="tolerance"></vue-slider> 
		</div>
		<div :title="'Tolerance for share-limits: +'+tolerance+'%'" style="display: inline-block; width: 8%; float: right;  margin-top: 1pt; font-size: 8pt;"> 
			Tol.: {{ tolerance }}%
		</div>
	</div>
		<div style='padding-top: 5pt;'></div>
		
		    <div style="width: 50%; font-weight: bold; font-size: 9pt; text-align: center; display: inline-block; float: top;">
		    Filter
		    </div>   
		    
		    <div style="width: 6%; font-weight: bold;  font-size: 9pt; text-align: center;  padding-left: 8pt; display: inline-block; padding-top:0pt;">   
			Max. 
			</div>   
			<div title="value in percentage" style="margin-left: 22pt; font-weight: bold;  font-size: 9pt; text-align: center;  width: 10%; padding-left: 5pt; display: inline-block; padding-top:0pt;"> 
			Type
			</div>
			<!--
			<div title="add negative share (opposite share)" style="width: 10%; font-weight: bold;  font-size: 9pt; text-align: center;  padding-left: 5pt; display: inline-block; padding-top:0pt;"> 
			Neg
			</div>
			-->
			<div style="width: 15%; padding-left: 5pt; display: inline-block; padding-top:0pt;"> 
		    </div>
        <div style='overflow-y: auto; height: calc(33vH);'>
			<div v-for="(sh,i) in value" style="width: 100%; display: flex; margin: 0;" :key="'sh_'+i">
				<div style="width: 50%; display: inline-flex; float: bottom;">
				
			    <InventoryFilterEditor  
			             	  	v-model="inventories[i]"
			             	  	:columnName="'inventory'"
			             	  	:parentRecord="sh"
			             	  	:hideBorder="true"
			             	  	:count="count"
			             	  	:hideTemplates="false"
			             	  	:columnFilter="{column: 'usage', values: [0,2]}"
			             	  	@open="openInvEditor"
			             	  ></InventoryFilterEditor>	
			    </div>   
			    
			    <div style="width: 12%;  padding-left: 1pt; display: inline-block; padding-top:0pt;">   
				<InputFieldS  v-model="sh.amount"/>
				</div>   
				
				<div title="share based on" style="margin-left: 1pt; height: 22pt; width: 18%; padding-left: 1pt; display: inline-block; padding-top:0pt;"> 
				<select  :style="getAppStyle()+'width:95%; height: 22pt; font-size: 7pt;'" v-model="sh.type" >
					<option v-for="(t,ti) in getShortTypes()" :key="'sht_'+ti" 
					    :selected="ti==sh.type" :value="ti">
						{{ t }}
					</option>
				</select>
				</div>
				
				<div style="width: 18%; float: right; display: inline-block; padding-top:0pt;"> 
				
				<button @click="removeShare(sh)" title="remove" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="close" /></button>
				<button @click="copyShare(sh)" title="copy" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="content-copy" /></button>
				<button v-if="sh.addNegative && sh.type <= 3" :disabled="sh.type > 3" @click="sh.addNegative=false" title="remove negative share" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="lock" /></button>
				<button v-else :disabled="sh.type > 3" @click="sh.addNegative=true" title="add negative (opposite) share" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="lock-open-variant-outline" /></button>
				
				</div>
			   
			</div>
			<div style='display: table; position: absolute; bottom: 0; width: 100%;'>
		    <button title="create share" :style="getAppStyle()+'float: right;'" class='button myButton' @click='reload()'><mdicon :width="24" name="reload" /></button>
			<button :style="getAppStyle()+'float: right;'" class='button myButton' title="check shares against bookings" @click='checkShares()'><mdicon :width="16" name="list-status" /></button>	
			<button title="reload" :style="getAppStyle()+'float: right;'" class='button myButton' @click='createShare()'><mdicon :width="24" name="share-circle" /></button>
			</div>	
  		</div>
  		</div>
  		<GFWEditorInventory :ref='getInvEditorId()' ></GFWEditorInventory>
  </div>
  
</template>

<script>
import {HTTP, fwAPI, userAPI, invAPI, bngAPI, opsAPI, reportAPI, inventoryInlineAPI, setReload, formatNumber,showError} from '@/variables.js';
import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import InputField from '@/components/booking/InputField';
import InputFieldS from '@/components/inputElements/InputMoneyNC';
//import InputFieldS from '@/components/booking/InputFieldS';
import Switch from '@/components/Switch';
import vSelect from 'vue-select'
import ClickOutside from 'vue-click-outside';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
import GFWEditorInventory from '@/components/GFWEditorInventoryInline';
export default {
  name: "SHARES",
   components : {
	   //'app-switch': Switch, GFWEditorInventory,
	   InventoryFilterEditor, InputFieldS, GFWEditorInventory
  },
  props: {
	  value: Array,
	  shareTypes: [],
	  dataId: { type: Number, default: 0},
	  count: { type: Number, default: 0},
	  simple: { type: Boolean, default: true},
	  packed: { type: Boolean, default: false},
  },
  data(){
    return {
		myId: Math.round(Math.random()*100000000),
    	shares: [],
    	inventories: [],
    	tolerance: null,
    	sharesInfo: {},
    	getAppStyle, getBGStyle2, getBGStyle3,
    	isPacked: this.packed
     }
    },
    
    directives:{
        ClickOutside,
      },
    methods: {
		getInvEditorId() {
	    	return 'INVEDIT'+this.myID; 
	    },
	    getInvEditor() {
	    	if ( Array.isArray(this.$refs[this.getInvEditorId()]))
			{
				return this.$refs[this.getInvEditorId()][0];
			}
			else
			{
				return this.$refs[this.getInvEditorId()];
			}
	    },
	    createShare( )
	    {
		   let copy = { name: "new share", type: 0, tolerance: this.tolerance};
		   this.value.push( JSON.stringify( copy));  
		   this.inventories.push( copy);
	    },
	    reload( )
	    {
		   let copy = { name: "new share", type: 0, tolerance: this.tolerance};
		   //this.value.push( JSON.stringify( copy));  
		   //this.value.pop();
		   this.inventories.push( );
		   this.pop();
	    },
		copyShare(share)
		{
			let copy = {...share};
			copy.id = 0;
			this.value.push( copy);  
			this.inventories.push( JSON.parse( copy.inventory));
		},
		
		removeShare(share)
		{
			let shareStr = JSON.stringify( share);
			this.value = this.value.filter(p=>JSON.stringify( p)!==shareStr);  
		},
		setTolerance()
		{
			if (this.tolerance !== null )
			{
				for ( let sh in this.value)
				{
					let share = this.value[sh];
					share.tolerance = this.tolerance;
				}
			}
		},
		getTolerance()
		{
			for ( let sh in this.value)
			{
				this.inventories[sh] = JSON.parse( this.value[sh].inventory);
			}
			
			for ( let sh in this.value)
			{
				let share = this.value[sh];
				this.tolerance = share.tolerance;
				break;
			}
			if (this.tolerance === null )
			{
				this.tolerance = 5;
			}
		},
		
		changeLabel( shareType)
		{
			if ( shareType.indexOf('Percent') >= 0)
			{
				let s = shareType.replace('Percent','');
				return "% "+s;
			}
			return shareType;
		},
		getShortTypes()
		{
			return this.shareTypes.map( p=>this.changeLabel(p));
		},
		openInvEditor( filter, record, columnName, forceOpen)
        {
      		//this.getInvEditor().open( filter, record, columnName, forceOpen, this.setInventoryShare);
      		this.$emit("openInvEditor", filter, record, columnName, forceOpen)
        },
        setInventoryShare( filter, record, column)
        {
			alert( JSON.stringify( filter))
		    record[column] = JSON.stringify(filter[column]);
			
        },
		openInvEditorX( filter, record, columnName, forceOpen)
        {
         this.$emit( 'openInvEditor', filter, record, columnName, forceOpen);
        },
        checkShares()
	    {
			//alert( JSON.stringify( this.value))
	    	let that = this;
	    	let copy = [...this.value];
	    	for ( let si in copy)
	    	{
				copy[si] = {...copy[si]};
				try {
				copy[si].inventory = JSON.parse( copy[si].inventory );
				} catch (e)
				{
					//
				}
			}
	    	let options = {shares: copy};
	    	//alert( JSON.stringify( options))
	    	HTTP.post( bngAPI+"/checkShares/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.dataId, options )
	 		 .then( response => 
	          {  
				  
	        	  that.sharesInfo = response.data;
	        	  
	        	  
	          }).catch(e => {
	        	  
	              that.$toast.error("loading data: " + e.response.data, 'Error', { position: "topRight" });
	              
	          });
	    },
  },
   updated() {
    
  },
  computed: {
	
  },
  
  created() {
  	 this.getTolerance();
  },
   watch:
   {
	   tolerance: function() { if (this.tolerance !== null ) this.setTolerance(); },
   	   value: function() { this.getTolerance(); this.$emit("input", this.value); },
   	   count: function() { this.getTolerance(); this.$emit("input", this.value); }
   }
}
</script>
<style  scoped>
.myButton {
	padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonS {
    padding: 1px 2px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #ccc;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 2pt;
    margin-bottom: 0pt;
} 
.smallText2 {
  
  overflow: hidden;
  text-align: right;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 14px;
  height: 16pt;
  padding-top: 2pt;
  display: table-cell;
}
.smallText2x { 
  //width: calc(30vH);
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 14px;
  height: 16pt;
  padding-top: 2pt;
  //display: inline-block;
}
.smallText2bx { 
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  height: 16pt;
  padding-top: 2pt;
}
.smallText2rx { 
  overflow: hidden;
  text-align: right;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  height: 16pt;
  padding-top: 2pt;
}
</style>