 <template>
  <div 
  class="modal fade modal-mask"
    :style=getModalStyle()
    id="fwEditModal"
    tabindex="1"
    :z-index="zIndex"
    role="dialog"
    aria-labelledby="fwEditModal"
    aria-hidden="true"
  >
   <div class="modal-wrapper">
     <div class="modal-container" :style='getAppStyle()+getContentStyle()'>
         <div class='appHead'>
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'> </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='maximize()' aria-label="Maximize">
              <span aria-hidden="true">
              <svg v-show='width != 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
				  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
			  </svg>
              <svg v-show='width == 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
				  <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
			   </svg>
              </span>
              
            </button>
            <button id='closePopup2' type="button" class="iButton" @click='closeUntilPosition(-1)' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
            </button>
            </div>
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
             
            	<div class='appLeft'>
            	  
            	  <div style='float: left'>
                  <a v-for="(p,idx) in path" :key="idx" @click=closeUntilPosition(idx) class='breadcrumbItem'>{{p.name}}</a>
            	  <div class='breadcrumbItemCurrent'>
            	  {{data.visualDisplay}}</div>
            	  
                </div>
            	</div>
                <div class='appRight'>
                  {{(metadata && metadata.menuTitle)?metadata.menuTitle:metadata.name}}
            	  <span class='appCounter'>{{visualIndex+1}}/{{numberOfRows}}</span>
                </div>
                
                </div>
            
           </slot>
        </div>
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div class='mainContent' >
            
            <div >
            
            <span class='elementLabel required' style='width:10%;'>Media kind</span>  
            <span class='editorElement' style='width:40%;'>
          
              <GSelectM  :visible="true"
                        :useCache=false
                        :disabled="!findColumn('mediaKindId').editable"
                        :entityMap="entityMap"
                        :parentMeta=parentMeta
    					:parentId=parentId
                         v-model="data.mediaKindId"
                         @open=openLink
                         @input=mediaKindChanged
                        :data=data :id=data.id :time="new Date().getTime()" :metadata=metadata :definition="findColumn('mediaKindId')" >
               </GSelectM>
            </span>   
            <span class='elementLabel required' style='width:10%;'>Behaviour</span>  
            <span class='editorElement' style='width:40%;'>
            <button @click='setBlacklistOnOff' :class='getOnOffStyle(data)' style='height: 20pt;'>{{getOnOff(data)}}</button>
            </span>   
            <br/>

             <InventoryPanel
             	:filterEntities=filterEntities
             	:activeFilter=activeFilter
             	:filterObjectArr=filterObjectArr
             	:invFilterArrayMatched=invFilterArrayMatched
             	:invFilterArrayDisabled=invFilterArrayDisabled
             	:allOperators=allOperators
             	:opArray=opArray
             	@addFilter=addFilter
             	@removeFilter=removeFilter
             	@setOperator=setOperator
		        @setOnOff=setOnOff
		        @setValues=setValues
		        @setValueFromTo=setValueFromTo
             	></InventoryPanel>
            
             </div>
            
              
              <table width='100%' >
              <tr>
              <td :class="msgClass">
                {{ message }} 
              </td>
              </tr>
              </table>
              </div>
              <div style='border-top: 1pt solid #bbbbff;'>
              <table width='100%'>
             
              <tr>
              <td width="20%" class='lUpdateInfo'>Last Update</td><td><InputDateTime size="11pt" :disabled=true v-model=data.lUpdate /></td>
              <td rowspan="2" width="20%" class='lUpdateInfoSuccess'><div v-show=success id='showSucess' >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#8c8" class="bi bi-check2" viewBox="0 0 16 16">
				  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
				</svg>
              </div></td>
              </tr>
              <tr>
              <td class='lUpdateInfo'>Last User</td><td class='lUpdateInfo'>{{ lastUser.login }}</td>
                            
              </tr>
              </table>
            </div>
              
               <div class="modal-footer" style='width: 100%; height: 80pt; float: right;'>
               <div style='display: inline-flex; float: right;'>
               
             
                <button type="button" class="button btn-secondary" @click="close()" data-dismiss="modal">Close</button>
                <button type="button" class="button btn-secondary" @click="reload(data.id)" data-dismiss="modal">Reload</button>
                <button type="button" class="button btn-primary btn-save"   @click.prevent="update()">Save</button>
              </div>
               
               <div style='display: inline-flex; float: right; padding-top: 4pt; padding-right: 20pt;'>
                <button type="button" class="button btn-nav" :disabled='visualIndex == 0'                  @click="first()">|&lt;</button>
                 <button type="button" class="button btn-nav" :disabled='visualIndex <= 0'                  @click="left()" >&lt;</button>
                <button type="button" class="button btn-nav" :disabled='visualIndex+1 >= visualMapping.length' @click="right()">&gt;</button>
                <button type="button" class="button btn-nav" :disabled='visualIndex+1 == visualMapping.length' @click="last()" >&gt;|</button>
               
               </div>
               <div style='display: inline-flex; float: right; padding-right: 20pt;'>
               <button type="button" class="button btn-secondary" @click="getShowAsText(data.id)" data-dismiss="modal">As Text</button>
               <button type="button" class="button btn-secondary" @click="getSQL(data.id)" data-dismiss="modal">SQL</button>
               <button type="button" class="button btn-secondary" @click="findInventory(data.id)" data-dismiss="modal">Search Inventory</button>
               </div>
             </div>
             
            
         </slot>
      </div>
    </div>
    
  </div>    
 
  <InfoPopUp :time=infoTime :infoType=asText :info=showAsText></InfoPopUp>
  <GFWEditorRouter v-if='showEditorDetail' 
    @close="closeEditorDetail" 
    @closeUntilPosition="closeUntilPosition"
    :parentMeta=currentParentMeta
    :parentId=currentParentId
    :entityMap=detailEntityMap
    :parentRecord=currentDataRecord
    :metadata="metadataDetail" 
    :path=addPath()
    :mapping=allDetailIDs
    :zIndex=incZIndex()
    :time="new Date().getTime()"  
    @update="passUpdate" @insert="passInsert"
    :dataRecord="selectedDetail">
   </GFWEditorRouter>  
   <ErrorPopUp :errorType="errorTypePU" :error="errorPU"></ErrorPopUp>
  </div>
</template>

<script>
import GLink from '@/components/GLink';
import GSelect from '@/components/GSelect';
import GSelectEntity from '@/components/GSelectEntity';
import GSelectModel from '@/components/GSelectModel';
import GSelectM from '@/components/GSelectM';
import InputInventoryFilter from '@/components/inputElements/InputInventoryFilter';
import InputText from '@/components/inputElements/InputText';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import GImage from '@/components/GImage';
import InputSecret from '@/components/inputElements/InputSecret';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InfoPopUp from '@/components/InfoPopUp';
import InputDouble from '@/components/inputElements/InputDouble';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputDatePick from '@/components/inputElements/InputDatePick';
import InventoryPanel from '@/components/GFWEditorInventoryPanel';
import Switch from '@/components/Switch'
import ErrorPopUp from '@/components/ErrorPopUp';
import {HTTP, fwAPI, invAPI, inventoryInlineAPI, showError } from '@/variables.js'
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import { Tabs, TabPane } from 'vue-bulma-tabs'
var timers = [];
export default {
  name: "GFWEditorInventory",
   components : {
    InputDateTime,InventoryPanel,
    GSelectM, ErrorPopUp, InfoPopUp
  },
  props: {
    metadata: Object,
    dataRecord: Object,
    parentMeta: Object,
    parentId: { type: Number, default: 0 },
    parentRecord: Object,
    time: { type: Number, default: 0 },
    zIndex: { type: Number, default: 90 },
    dataArray: { type: Array, default: () => [] },
    entityMap: { type: Object, default: () => {} },
    detailEntityMap: { type: Object, default: () => {} },
    mapping: { type: Array, default: () => [] },
    selectedIndex: { type: Number, default: 0 },
    dataId: { type: Number, default: 0 },
    path: { type: Array, default: () => [] },
    display: Boolean
  },
  data(){
    return {
        success: false,
        index: this.selectedIndex,
        creatables: [],
        asText: "",
        showAsText: "",
        infoTime: 0,
        data: this.copy(this.dataRecord),
        selectedTab: 0,
        visualMapping: Array,
        visualIndex: 0,
        numberOfRows: 0,
        lastUser: {},
        width: 75,
        height: 90,
        mainHeight: 75,
        allDetailIDs: [],
        selectedDetail: {},
        metadataDetail: {},
        showEditorDetail: false,
        msgClass: "msgDefault",
        allOperators: [],
        invFilterArray: [],
        invFilterArrayMatched: [],
        invFilterArrayDisabled: [],
        activeFilter: [],
        filterEntities: [],
        filterObjectArr: [],
        valueStruct: [],
        opArray: [],
        message: "",
        errorPU: {},
        errorTypePU: "",
        updateCount: 0,
		currentDataRecord: this.data,
        currentParentId: 0,
        currentParentMeta: {},
        loaded: false,
        getAppStyle
     }
    },
  methods: {
        name() { return "FWViewer"+this.zIndex; },
        tooltipFilterAdd(txt) { return "add filter: " + txt; },
        tooltipFilterAdded(txt) { return "filter: " + txt + " already used"; },
        close() {this.selectedTab = 0; this.$emit('close')},
        copy(r) { let aCopy = {}; Object.assign( aCopy, r); return aCopy; },
        getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
        getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
        incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
        decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
        maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
        isString(column) { return column.editor == 'STRING'; },
        isTz(column) { return column.editor == 'SELECT' && column.visualDetail == 'TZ' && !column.array; },
        isLink(column) { return column.editor == 'LINK'; },
        isSelect(column) { return column.editor == 'SELECT' && column.visualDetail != 'TZ' && !column.array; },
        isMSelect(column) { return column.editor == 'SELECT' && column.visualDetail != 'TZ' && column.array; },
        isInt(column) { return column.editor == 'INT'; },
        isDouble(column) { return column.editor == 'DECIMAL'; },
        isSecret(column) { return column.editor == 'SECRET'; },
        isBool(column) { return column.editor == 'BOOLEAN'; },
        isTime(column) { return column.editor == 'TIME'; },
        isWeekday(column) { return column.editor == 'WEEKDAYS'; },
        isDate(column) { return column.editor == 'DATE'; },
        isDatetime(column) { return column.editor == 'DATETIME'; },
        isImg(column) { return column.editor == 'IMAGE';},
        getCreatables( links, data)
        {
        	var newArray = [];
        	for ( var i in links )
        	{
        		if ( ! links[i].createIf)
        		{
        			newArray.push( links[i]);
        		}
        		else 
        		{
        			if ( data[ links[i].createIf ] )
        			{
        				newArray.push( links[i]);
        			}
        		}
        	}
        	return newArray;
        },
        addFilter(filter, onOff, filterObject)
        {
			
            let index = this.filterEntities.length;         	
        	this.filterEntities.push( filter );
        	
        	if ( filterObject ) 
        	{
        		this.filterObjectArr[ index ] = filterObject;
        		this.opArray[index] = filterObject.operatorId;
        	}
        	else
        	{
        		var opId = 0;
        		var operator = {};
        		
        		if ( filter.operators.length == 1 )
        		{
        		    opId = parseInt(filter.operators[0]);
        			operator = this.allOperators.find( op => op.id === opId);
        		}
        		this.filterObjectArr[ index ] = { type: filter, operator: operator, operatorId: opId, positive: onOff } 
        		
        		if ( filter.typeValue=='Date')
        		{
        			this.filterObjectArr[ index ].struct = { from: new Date().toLocaleDateString('en-CA'), to: new Date().toLocaleDateString('en-CA')}
        		}
        		this.opArray[index] = this.filterObjectArr[ index ].operatorId;
        	}

        	this.activeFilter[ index] = true;
			this.invFilterArrayDisabled[ filter.id ] = true;
			
        	return index;
        },
        putFilter( filterObject )
        {
            //alert( "PUT> "+ JSON.stringify(filterObject));
        	let index = this.addFilter( filterObject.type, filterObject.positive, filterObject)
        },
        removeFilter(idx)
        {
            this.activeFilter[ idx] = false;
            this.invFilterArrayDisabled[ this.filterEntities[idx].id ] = false;
        	this.filterEntities.push( {} );
        	this.filterEntities.pop();
        	
        },
        getFilters()
        {
        
        	console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/InventoryFilterType/allEntities/true/0"); 
    		HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/InventoryFilterType/allEntities/true/0") 
            .then( response => 
               { 
                 
                 this.invFilterArray = response.data.data;
                 this.invFilterArrayMatched = this.checkFilterArray( this.invFilterArray, this.data.mediaKindId);
               }).catch(e => {
                           alert( e.response.status + " " + JSON.stringify(e.response.data) )
             });
        
        
        },
        containsAll( filterKind, inventoryKind) 
        {
     	    // converting into Set
		    const filterSet = new Set(filterKind);
		    const inventorySet = new Set(inventoryKind);

		    for (let i of inventorySet) {
		    
		        if (! filterSet.has(i)) {
		            return false
		        }
		        
			}
    
    		return true;
    	},
        checkFilterArray( invFilterArray, mediaKind) 
        {
            var invFilterArrayMatched = [];
            
        	for ( var idx in invFilterArray )
        	{
        		let filter = invFilterArray[ idx];
        		
        		//alert( JSON.stringify(filter.mediaKindId ) +"/"+mediaKindId+"/"+this.containsAll(filter.mediaKindId, mediaKind));
        		if ( this.containsAll(filter.mediaKindId, mediaKind) )
        		{
        			invFilterArrayMatched.push( filter);
        		}
        	}
        	for ( idx in this.filterEntities )
        	{
        	    let filter = this.filterEntities[ idx];
        	    //alert( JSON.stringify( filter.name) +" :: "+ JSON.stringify( filter.mediaKindId) +" = "+ this.containsAll(filter.mediaKindId, mediaKind));
        		if ( !this.containsAll(filter.mediaKindId, mediaKind) )
        		{
        			this.removeFilter( idx);
        		}
        	}
        	invFilterArrayMatched.sort( function(a,b) {
        		if (a.name > b.name ) return 1;
        		if (a.name < b.name ) return -1;
        		return 0;
        	});
        	return invFilterArrayMatched;
        },
        mediaKindChanged( mediaKind )
        {
            
        	if ( mediaKind  )
        	{
        		//alert( "mediaKindChanged(" + mediaKind.id +" - "+ this.data.mediaKindId);
        		this.invFilterArrayMatched = this.checkFilterArray( this.invFilterArray, mediaKind);
        	}
        },
        error( txt, err)
	    {
	      if ( err && err.response && err.response.data )
	      {
	      	this.printMsg( err.response.status, txt+ ": " +err.response.data);	
	      }
	      else 
	      {	
	      	this.errorTypePU=txt;
	      	if ( ! err )
	      	{
	        	this.errorPU = {'message': ""};
	      	}
	      	else
	      	{
	      		this.errorPU = err;
	      	}
	      }
	    },
        getDisplay(col) 
        { 
            if (this.visible(col)) 
            {
                let column = this.findColumn(col);
            	return column.display; 
            }
            
            return "";
        },
        visible(column) { return column && column != null && column != 'null';},
        getModalStyle() { return "'z-index: " + (2+this.zIndex)+";'"; },
        incZIndex() { return parseInt(this.zIndex)+1; },
        clearMsg()
        {
        	this.message = "";
        },
        printMsg(status, msg)
        {
        	if ( parseInt(status) != 200 )
        	{
        		this.msgClass = "msgError";
        	}
        	else
        	{
        		this.msgClass = "msgDefault";
        	}
        	timers.push( setTimeout(this.hideSuccess, 4000));
        	this.message = msg;
        },
        findColumn(column) { 
           //console.log( column );
           var colObject=this.metadata.columns.find(p => p.column === column); 
           //console.log( colObject );
           if ( colObject ) { 
              return colObject; 
           } 
           else { 
              colObject=this.metadata.links.find(p => p.name === column); 
              if ( colObject ) { 
              	return colObject; 
              } 
              else {
                return {};
              }
           }
        },
        addPath()
        {
        	var p = JSON.parse(JSON.stringify(this.path));
        	p.push({name: this.data.visualDisplay, entity: this.metadata.name, position: p.length});
        	return p;
        },
        getShowAsText( id )
        {	
       // alert(invAPI+"/describeInventoryFilters/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id);
        	HTTP.get( invAPI+"/describeInventoryFilters/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id)
        		 .then( response => 
                 {  
                    this.showAsText = "<ul style='padding-left: 3ex; list-style-type: circle;'>";
                    for ( var i = 0; i < response.data.length; i++ )  
                    { 
                    	this.showAsText += "<li>" + response.data[i]+ "</li>";
                    }
                    this.showAsText += "</ul>";
                    //this.showAsText = JSON.stringify(response.data);
                    this.infoTime = new Date().getTime();
                    this.asText = this.data.name;
                        
                 }).catch(e => {
                        //this.error("getInventoryFilters() failed", e);
                        this.showAsText = JSON.stringify(e);
                        this.infoTime = new Date().getTime();
                        this.asText="error";
                       
                    });
        }, 
        findInventory()
        {	
        	
            let now = new Date().toISOString().split('T')[0];
       	    let data = this.toData();
       	    HTTP.post( inventoryInlineAPI+"/findInventory/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+now+"/"+now+"/"+sessionStorage.timezoneId+"/0", data)
        		 .then( response => 
                 {  
                    
                    this.showAsText = "<ul style='padding-left: 3ex; list-style-type: circle;'>";
                    for ( var i = 0; i < response.data.length; i++ )  
                    { 
                    	this.showAsText += "<li>" + response.data[i].detail.name+ " ["+response.data[i].media.shortname+"]"+ "</li>";
                    }
                    this.showAsText += "</ul>";
                    //this.showAsText = JSON.stringify(response.data);
                    this.infoTime = new Date().getTime();
                    this.asText = this.data.name;
                        
                 }).catch(e => {
                        //this.error("findInventory() failed", e);
                        this.showAsText = JSON.stringify(e);
                        this.infoTime = new Date().getTime();
                        this.asText="error";
                       
                    });
        }, 
        
        getSQL( id )
        {	
       // alert(invAPI+"/getSQL/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id);
        	HTTP.get( invAPI+"/getSQL/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id)
        		 .then( response => 
                 {  
                    this.showAsText = response.data;
                    //this.showAsText = JSON.stringify(response.data);
                    this.infoTime = new Date().getTime();
                    this.asText = this.data.name;
                        
                 }).catch(e => {
                        //this.error("findInventory() failed", e);
                        this.showAsText = JSON.stringify(e);
                        this.infoTime = new Date().getTime();
                        this.asText="error";
                       
                    });
        }, 
        newDetail(data, link)
        {
        	//console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/new/"+link );
        	if ( !data.id || ! data.id > 0 )
        	{
        		this.error("Please, save the data first", "");
        		return;
        	}
        	let myLink = this.metadata.links.find( l=>l.entity == link);
        	let fromEntity = this.metadata.name;
        	let parentID = data.id;
        	let parentMeta = this.metadata;
        	let parentRecord = data;
        	if ( ! myLink && this.parentMeta  )
        	{
        		myLink = this.parentMeta.links.find( l=>l.entity == link);
        		fromEntity = this.parentMeta.name;
        		parentID = this.parentId;
        		parentMeta = this.parentMeta;
        		parentRecord = this.parentRecord;
        	}
			
			HTTP.post( fwAPI+"/createNewDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromEntity+"/"+link, parentRecord)
                    .then( response => {
                    this.selectedDetail = response.data.data;
                    this.selectedDetail.tags= new Array();
                    this.metadataDetail =  response.data.metadata;
                    this.detailEntityMap = response.data.entity2DataMap;
                    //console.log( JSON.stringify( this.selectedDetail));

                    this.currentParentId = parentID;
					this.currentParentMeta = parentMeta;
					this.currentDataRecord = parentRecord;
					this.showEditorDetail = true;

                    //
                }).catch(e => {
                     this.error("can't create new "+link, e);
                });
        	
        },
        openLink(id, link, allIds)
        {
        	//console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/"+id+"/"+link );
        	this.allDetailIDs = [];
        	if ( allIds )
        	{
				for ( var x in allIds )
				{
					this.allDetailIDs.push({row: x, dataId: allIds[x], rId: allIds[x], visible: true });
				}
			}
			else
			{
				this.allDetailIDs.push({row: 0, dataId:id, rId: id, visible: true });
			}
			this.currentParentId = this.visualMapping[ this.visualIndex ].dataId;
			this.currentParentMeta = this.metadata;
			HTTP.post( fwAPI+"/getLinkedDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/"+id+"/"+link)
                    .then( response => {
                    this.selectedDetail = response.data.data;
                    this.metadataDetail =  response.data.metadata;
                    this.detailEntityMap = response.data.entity2DataMap;
                    //console.log( "OPEN-"+link+ " " +JSON.stringify( this.detailEntityMap));
					this.showEditorDetail = true;
					//alert( JSON.stringify(this.selectedDetail));
                    //
                }).catch(e => {
                        this.error("can't open "+this.metadata.name, e);
                });
        	
        },
        closeUntilPosition(position)
        {
        	//alert( position +"/" + this.path.length);
        	if ( position < this.path.length )
        	{
        		this.close();
        	}
        	this.$emit("closeUntilPosition", position)
        },
        closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
        getImgData(val) { return 'data:image/png;base64,' + val; },
        hideSuccess() { this.success = false; this.clearMsg(); clearInterval( timers.pop()); },
        getData(id)
        {
        	//alert( "getData");
        	//console.log( fwAPI+"/"+this.metadata.name+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+id);
            HTTP.get( fwAPI+"/entityById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/"+id)
                    .then( response => 
                    {
                    	if ( ! response.data.metadata )
                    	{
                         	this.data = response.data;
                         	this.getUser(this.data.lUserId); 
                        }
                        else
                        {
                        	this.data = response.data.data;
                        	this.metadata = response.data.metadata;
                         	this.getUser(this.data.lUserId); 
                        }
                        
                    }).catch(e => {
                        this.error("can't get "+this.metadata.name+" data ID="+id, e);
                    });
        },
        left() {
            if (this.visualIndex > 0 )
            {
                this.visualIndex--;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	this.readDataRecord(id);
                }
                else
                {
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getInventoryFilters();
                	this.getUser(this.data.lUserId); 
                }
                
            }
        },
        right() {
            if (this.visualIndex+1 < this.visualMapping.length )
            {
                this.visualIndex++;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	this.readDataRecord(id);
                }
                else
                {
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getInventoryFilters();
                	this.getUser(this.data.lUserId); 
                }
                
            }
        },
        last() {
            if (this.visualIndex+1 < this.visualMapping.length )
            {
                this.visualIndex = this.visualMapping.length-1;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	this.readDataRecord(id);
                }
                else
                {
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getInventoryFilters();
                	this.getUser(this.data.lUserId); 
                }
            }
        },
        first() {
            if (this.visualIndex > 0 )
            {
                this.visualIndex = 0;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                if (! this.dataArray.find(p => p.id == id))
                {
                	this.readDataRecord(id);
                }
                else
                {
                	Object.assign( this.data, this.dataArray.find(p => p.id == id));
                	this.getInventoryFilters();
                	this.getUser(this.data.lUserId); 
                }
            }
        },
        folderColumnsIndex(tabColumns) {
            var intArr = [];
            for ( var i=0; i < tabColumns.length; i++ )
            {
                intArr.push(i);
            }
            return intArr;
        },
        // style='display: inline-flex; width: 25%;'
        toolTip( tab, colIdx)
        {
        	 let columnName = tab.columns[colIdx];
             let column = this.findColumn(columnName)
        	 if ( column.tooltip )
        	 {
        	 	return column.tooltip;
        	 }
        	return {};
        },
        getLabelClass( tab, colIdx) 
        {
        	 let columnName = tab.columns[colIdx];
             let column = this.findColumn(columnName)
        	 if ( column.mandatory )
        	 {
        	 	return "elementLabel required";
        	 }
        	return "elementLabel";
        },
        getStyle( tab, colIdx) 
        { 
            let column = tab.columns[colIdx];
            let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: column;
            if (this.visible(column)) {
                if ( this.visible(columnNext) ) { 
                    return "width: " + Math.round(100.0/tab.cells)+"%;";
                }
                else 
                {
                    //console.log("column " + column+ " " + "width: " + Math.round(200.0/tab.cells)+"%;");
                    return "width: " + Math.round(200.0/tab.cells)+"%;";
                }
            } else {
                return "display: none;";
            } 
        },  
        getLabelStyle(tab, colIdx) 
        { 
            let columnName = tab.columns[colIdx];
            let column = this.findColumn(columnName)
            let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: columnName;
            let width = 25.0;
            let unit = "%;";
            
            let fontStyle = (column.tooltip)? "text-decoration: underline;": "";
            if (this.visible(column.column)) {
                if ( this.visible(columnNext) ) 
                { 
                    return fontStyle+"width: " + (width)+unit;
                }
                else 
                {
                    return fontStyle+"width: " + (width/tab.cells)+unit;
                }
            } else {
                return fontStyle+"width: " + (width)+unit;
            } 
        },  
        passUpdate(entity, record) {
            this.updateCount++;
        	this.$emit( "update", entity, record);
        },
        passInsert(entity, record) {
            this.updateCount++;
        	this.$emit( "insert", entity, record);
        },
        setOperator(index, operatorData)
        {
           
            this.filterObjectArr[index].operator= operatorData;
            //this.filterObjectArr[index].operator = JSON.parse( JSON.stringify(operatorData));
            this.filterObjectArr[index].operatorId = operatorData.id;
        	this.activate( index);
        	
        },
        setOnOff(index, onOff)
        {
            //alert(this.filterObjectArr[index].positive+ "-->" + onOff)
        	this.filterObjectArr[index].positive = (onOff);
			this.activate( index);
        },
        activate( index )
        {
        	//var copy = {};
        	//Object.assign( copy, this.filterObjectArr[index]);
        	//this.filterObjectArr = JSON.parse(JSON.stringify(this.filterObjectArr)) ;
        	this.opArray[index] = this.filterObjectArr[index].operatorId;
        	this.opArray.unshift( 0 );
        	this.opArray.shift( );
        	this.filterObjectArr.push( this.filterObjectArr[index] );
        	this.filterObjectArr.pop();
        	//this.$forceUpdate();
        },
        setValues(index, values, labels)
        {
            //alert( index + "::-->" +  JSON.stringify(values))
        	this.valueStruct[index] = {value: values, labels: labels};
        	//alert(JSON.stringify( values));
        },
        setValueFromTo(index, from, to)
        {
        	this.valueStruct[index] =  {from: from, to: to};
        	//alert( JSON.stringify(this.valueStruct[index]))
			this.filterObjectArr[ index].struct = this.valueStruct[index];
        	this.$forceUpdate();
        	if ( this.filterObjectArr[index].type.typeValue && (this.filterObjectArr[index].type.typeValue === 'Date') )
        	{
        		this.valueStruct[index] =  {from: from, to: to};
        	}
        	else if ( from >= 0 && to >= 0 )
        	{
        		this.valueStruct[index] =  {from: from, to: to};
        	}
        	else if ( from )
        	{
        		this.valueStruct[index] =  {value: from};
        	}
        	else if ( to )
        	{
        		this.valueStruct[index] = {from: 0, to: to};
        	}
        	//alert( JSON.stringify(this.valueStruct[index]));
        },
        getInventoryFilters()
        {
        	alert(1)
        	if ( this.data.id && this.data.id > 0 )
        	{
        	    alert("load " + invAPI+"/getInventoryFilters/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.id);
        		HTTP.get( invAPI+"/getInventoryFilters/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.id)
        		 .then( response => 
                 {     
                    //alert( JSON.stringify(response.data));
                    let filters = response.data.resultList;
                    this.invFilterArrayDisabled = [];
                    this.filterEntities = [];
                    this.opArray = []; 
                   
                    this.allOperators = response.data.allOperators;
        			for ( var i in filters )
        			{
        				//alert( JSON.stringify(filters[i]));
        			    this.putFilter( filters[i] );
        			}
                 }).catch(e => {
                        this.error("getInventoryFilters() failed", e)
                    });
        	}
        	else
        	{
        		this.data.mediaKindId = [];
        	}
        },
        getStruct( struct)
        {
        	if ( Array.isArray(struct.from) && !struct.to )
        	{
        		return { values: struct.from};
        	}
        	if ( Array.isArray(struct.to) && !struct.from )
        	{
        		return { values: struct.to};
        	}
        	if ( struct.from && !struct.to )
        	{
        		return { value: struct.from};
        	}
        	if ( struct.to && !struct.from )
        	{
        		return { value: struct.to};
        	}
        	if ( Object.prototype.toString.call(struct.from) === '[object Date]' && Object.prototype.toString.call(struct.to) === '[object Date]')
        	{
        		return { from: struct.from.toLocaleDateString('en-CA'), to: struct.to.toLocaleDateString('en-CA') };
        	}
        	if ( struct.from && struct.to )
        	{
        		return { from: struct.from, to: struct.to };
        	}
        	if ( Array.isArray(struct.value) )
        	{
        		
        		return { values: struct.value, labels: struct.labels };
        	}
        	if ( struct.value )
        	{
        		return { value: struct.value, labels: [struct.labels] };
        	}
        },
        toData() 
        {
        	var arrayToUpdate = [];
        	for ( var i in this.filterEntities)
        	{
        	   if ( this.activeFilter[ i] )
        	   {
	         	let type = {entity: this.filterObjectArr[i].type.entityName, path: this.filterObjectArr[i].type.path, type: this.filterObjectArr[i].type.typeValue, id: this.filterObjectArr[i].type.id};
	         	let op = {name: this.filterObjectArr[i].operator.name, type: this.filterObjectArr[i].operator.typeValue, id: this.filterObjectArr[i].operator.id};
	         	let onOff = this.filterObjectArr[i].positive;
	         	//alert( i + "/"+JSON.stringify({id: id, operatorId: op, typeId: type, positive: onOff, values: v})); 
	         	
	         	arrayToUpdate.push({operator: op, type: type, positive: onOff, struct: this.getStruct(this.valueStruct[i])});
	           
        	   }
        	}
        	let data = { blacklist: this.data.blacklist===true, filter: arrayToUpdate};
        	
        	return data;
        },
        update() { 
           
            let data = this.toData();
            this.data.filter = data;
            
			console.log( JSON.stringify(this.data)) 
        	HTTP.put( inventoryInlineAPI+"/saveFilter/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.data )
            .then( response => 
            {
                 this.$toast.success("saved", 'OK', { timeout: 1000, position: "topRight" });
                
            }).catch(e => {
                showError(this.$toast, e, "failed")
            });
        	
            
        },
        getUser(id)
        {
            this.lastUser = {};
            HTTP.get( fwAPI+"/findUser/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+id)
                    .then( response => 
                    {
                         this.lastUser = response.data;
                        
                    }).catch(e => {
                        this.error("getUser("+id+") failed", e)
                    });
        },
        selectTab(tab)
        {
        	var firstTab = (tab === this.metadata.folder[0]);
        	//console.log("selectTab() --> " + firstTab+ " :"+  JSON.stringify(tab));
        	return firstTab;
        },
        tabChange(index)
        {
        	this.selectedTab = index;
        	//console.log("tabChange() --> " +index);
        },
        reload(id)
        {
        	this.getFilters();
        	this.readDataRecord(id);
        },
        readDataRecord(id)
        {
        	if ( id > 0 )
        	{
        		this.getData( id);
        	}
        	else
        	{
        		this.filterEntities = [];
        		this.filterObjectArr =[];
				this.invFilterArrayDisabled = [];
        		this.activeFilter = [];
        	}
            
            this.dataId = this.data.id;
            
            
            for ( var col in this.metadata.columns) 
            {
            	let gCol = this.metadata.columns[col];
            	if ( gCol.enableIf )
            	{
            		let enableIfCol = this.findColumn( gCol.enableIf );
            		if ( this.data[ gCol.enableIf ] )
            		{
            			gCol.editable = true;
            		}
            		else
            		{
            			gCol.editable = false;
            		}
            	}
            }
            
            if ( this.data.lUserId )
            {
            	this.getUser(this.data.lUserId); 
            }
            this.index = this.selectedIndex; 
            this.selectedTab = 0;
            this.numberOfRows = 0;
            //console.log( JSON.stringify(this.mapping));
            this.visualMapping = [];
            this.visualIndex = 0;
            for ( var x in this.mapping )
            {
            	//console.log( JSON.stringify(this.mapping[x]));
            	if ( this.mapping[x].visible )
            	{
            		this.numberOfRows++;
            		
            		if ( this.data.id == this.mapping[x].dataId )
            		{
            			this.visualIndex = this.visualMapping.length;
            		}
            		this.visualMapping.push( this.mapping[x] );
            	}
            }
            this.getInventoryFilters();
        },
        getOnOff(data) {
      	if ( !data.blacklist )
      	{
      		return "FILTER";
      	}
      	else
      	{
      		return "BLACKLIST"
      	}
      },
      getOnOffStyle(data) {
      	if ( !data.blacklist)
      	{
      		return "button on"
      	}
      	else
      	{
      		return "button off"
      	}
      },
      setBlacklistOnOff() {
        //this.value.positive = !this.value.positive;
        this.data.blacklist = !this.data.blacklist;
      }
  },
   updated() {
     if ( !this.loaded )
     {
     	this.loaded = true;
     	this.reload(this.dataId);
     }
   
  },
  computed: {
	msgDisplayClass: function () { return this.msgClass; }
  },
  
  created() {
  			this.creatables = [];
   	    	for ( var i in this.metadata.links)
        	{
        		if ( this.metadata.links[i].createable )
        		{
        			this.creatables.push( this.metadata.links[i]);
        		}
        	}	
        	
        	if ( this.parentMeta )
        	{
	        	for ( var ii in this.parentMeta.links)
	        	{
	        		if ( this.parentMeta.links[ii].createable )
	        		{
	        			this.creatables.push( this.parentMeta.links[ii]);
	        		}
	        	}	
	        }
        	this.data = this.copy(this.dataRecord); 
        	
            if ( this.data.lUserId )
            {
            	this.getUser(this.data.lUserId); 
            }
            
            this.index = this.selectedIndex; 
            this.selectedTab = 0;
            this.numberOfRows = 0;
            //console.log( JSON.stringify(this.mapping));
            this.visualMapping = [];
            this.visualIndex = 0;
            for ( var x in this.mapping )
            {
            	//console.log( JSON.stringify(this.mapping[x]));
            	if ( this.mapping[x].visible )
            	{
            		this.numberOfRows++;
            		
            		if ( this.data.id == this.mapping[x].dataId )
            		{
            			this.visualIndex = this.visualMapping.length;
            		}
            		this.visualMapping.push( this.mapping[x] );
            	}
            }
  },
   watch:
   {
   	    metadata: function() {
   	        this.creatables = [];
   	   		
   	    	for ( var i in this.metadata.links)
        	{
        		if ( this.metadata.links[i].createable )
        		{
        			this.creatables.push( this.metadata.links[i]);
        		}
        	}	
   	    },

        dataRecord: function()  { 
            this.data = this.copy(this.dataRecord); 
            this.reload(this.dataRecord.id);
        	
        }
   }
}
</script>
<style  scoped>
.editorElement {
     display: inline-flex; 
     padding-bottom: 8pt;
     padding-top: 2pt;
     padding-right: 8pt;
 }
.elementLabel {
    display: inline-flex;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    display: inline-flex;
     //border: 1pt solid yellow;
     width: 100%;
 }
.lUpdateInfo {
    font-size: 11pt;
    color: #888;
}
.lUpdateInfoSuccess {
    vertical-align: middle;
    
}
.mainContent {
    height: 100%;
    transition: all 0.4s ease;
    //border: 1pt solid blue;
}
.appHeadLine { 
    width: 100%; 
    border-bottom: 1pt solid #aaa; 
    text-align: right;
    margin-bottom: 2pt; 
    font-weight: bold;
    padding: 10pt 10pt 1pt;}
    
.appCounter {
	display: inline-grid;
	font-size: 11pt;
	color: #888;
}    
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;}
    
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  //height: 60%;
  width: 60%;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
  //border: 1pt solid blue;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-nav {
    font-size: 9pt;
    vertical-align: middle;
    valign: middle;
}
.btn-save {
	background-color: #ded;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}
.msgDefault {
	font-size: 9pt;
}
.msgError {
	font-size: 9pt;
	font-weight: bold;
	color: #bb0000;
}
.on { background-color: #dfd; font-weight: bold; font-size: 8pt;}
.off { background-color: #000; color: #fcc; font-weight: bold; font-size: 8pt;}
</style>